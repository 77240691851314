let API_URL = process.env.REACT_APP_API_PROD_BASE_URL

export const get = async (endpoint, abortSignal) => {
    let signal = null; if (abortSignal) { signal = abortSignal; }
    return await fetch(`${API_URL}${endpoint}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include"
    });
}

export const getNoCreds = async (endpoint, abortSignal) => {
    let signal = null; if (abortSignal) { signal = abortSignal; }
    return await fetch(`${API_URL}${endpoint}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const post = async (endpoint, data) => {
    return await fetch(`${API_URL}${endpoint}`, {
        method: "POST",
        headers: {
            "Accept": "application/json */*",
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data)
    });
}

export const patch = async (endpoint, data) => {
    return await fetch(`${API_URL}${endpoint}`, {
        method: "PATCH",
        headers: {
            "Accept": "application/json */*",
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data)
    });
}

export const del = async (endpoint) => {
    return await fetch (`${API_URL}${endpoint}`, {
        method: "DELETE",
        headers: {
            "Accept": "application/json */*",
            "Content-Type": "application/json"
        },
        credentials: "include"
    });
}