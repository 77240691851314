import {get, post, del, patch} from "./APIServices";
import {useMutation, useQuery} from "react-query";

// This file is responsible for creating custom query and mutation hooks using the React Query library
// which will fetch and mutate data from the API

// NOTE: Each hook must begin with the "use" keyword to properly be recognized as a hook by React and React Query



// BUDGET HOOKS
// ----------------------------------------

// #Purpose - Fetches the current budget object from the database, which encompasses all info used by the Budget component
// #Returns - A JSON object called "Budget" which has the budget info, expenses, and expense categories
// #Use - Used by the Budget component to fetch all info related to the budget in one call.
export const useGetCurrentBudget= () => {
    return useQuery("getBudgetByMonthYear", async () => {

        // Determine the current local monthYear. E.g. "12-2023"
        let now = new Date();
        let monthYear = `${(now.getMonth() + 1).toString()}-${now.getFullYear().toString()}`;

        const res = await get(`budget/getByMonthYear/${monthYear}`);

        if (res.errored){
            throw new Error("Error when getting budget!");
        }

        return res.json().then((data) => {
            return data.budget;
        });
    });
}

// #Purpose - Updates the budget information, expenses, and expense categories in one endpoint
// @data - The JSON object containing the keys and their values to be updated
// #Returns - A JSON object called "Budget" which has the UPDATED budget info, expenses, and expense categories
// #Use - Used when a change is made on Budget component and should be sent to the backend
export const useUpdateBudget = () => {
    return useMutation("updateBudget", async (data) => {
        const res = await patch(`budget/${data.budgetId}`, data.budgetData);
         if (!res.ok){
             return res.json().then((data) => {
                 if (data.error === "UPDATE-BUDGET/EXPENSE-CATEGORY-NAME-TAKEN"){
                     throw new Error("Duplicate expense category names not allowed!");
                 }
                 throw new Error("Unknown Error while saving Budget!");
             });
         }

        return res.json().then((data) => {
            return data.budget;
        });
    });
}



// EXPENSE CATEGORY HOOKS
// ----------------------------------------

// #Purpose - Creates a new expense category with the given attributes
// @data - The JSON object containing the desired attributes of the new Expense Category
// #Returns - A JSON object of the Expense Category after being created with the backend API. Throws an Error obj if no success
// #Use - Used by the EditBudgetModal when a new Expense Category is created
export const useCreateExpenseCategory = () => {
    return useMutation('createExpenseCategory', async (data) => {
        const res = await post("budget/expenseCategory", data);
        if (res.errored){
            throw new Error("Error when creating expense category!");
        }

        return await res.json().then((data) => {
            return data.expenseCategory;
        });
    });
}


// #Purpose - Deletes an existing Expense Category from a user's budget
// @expenseCategoryId - The unique expenseCategoryId that is to be deleted
// #Returns - True if the deletion was successful. Throws an Error obj if no success
// #Use - Used by the EditBudgetModal when a new Expense Category is deleted
export const useDeleteExpenseCategory = () => {
    return useMutation("deleteExpenseCategory", async(expenseCategoryId) => {
        const res = await del(`budget/expenseCategory/${expenseCategoryId}`);
        if (res.errored) {
            throw new Error("Error when deleting expense category!");
        }
        return true;
    });
}

// #Purpose - Retrieves all the budgets a user has associated with their account
// #Returns - An array of all budget objects the user's account has. Throws an Error obj if no success
// #Use - Used by the Spending History tab to show all the previous budgets and their information
export const useGetAllBudgets = () => {
    return useQuery("getAllBudgets", async () => {
        const res = await get(`budget/history/all`);

        if (res.errored){
            throw new Error("Error when getting all budgets!");
            
        }

        return res.json().then((data) => {
            return data.budgets;
        });
    });
}




// EXPENSE HOOKS
// ----------------------------------------

// #Purpose - Creates a new expense in the user's current budget with the given data attributes
// @data - The JSON object attributes for the new Expense to have
// #Returns - Returns a JSON object representing the new Expense that was created. Throws an Error obj if no success
// #Use - Used by the budget component when a new Expense is added to the Expenses table
export const useCreateExpense = () => {
    return useMutation('createExpense', async (data) => {
        const res = await post("budget/expense", data);
        if (res.errored){
            throw new Error("Error when creating expense!");
        }

        return await res.json().then((data) => {
            return data.expense;
        });
    });
}

// #Purpose - Deletes an existing Expense from the user's current budget
// @expenseId - The unique id of the expense to delete
// #Returns - Returns the id the expense deleted if successful, throws an Error object if not
// #Use - Used by the budget component when a new Expense is added to the Expenses table
export const useDeleteExpense = () => {
    return useMutation('deleteExpense', async (expenseId) => {
        const res = await del(`budget/expense/${expenseId}`);
        if (res.errored){
            throw new Error("Error when deleting expense!");
        }

        return await res.json().then((data) => {
            return data.deletedExpenseId
        });
    });
}