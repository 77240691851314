import {get, post, del, patch} from "./APIServices";
import {useMutation, useQuery} from "react-query";

export const useCreateTask = () => {
    return useMutation('createTask', async (data) => {
        const res = await post("tasks", data);
        if (res.errored){
            throw new Error("Error when creating task!");
        }

        return await res.json().then((data) => {
            return data;
        });
    });
}

export const useUpdateTask = () => {
    return useMutation('updateTask', async (data) => {
        const res = await patch(`tasks/${data.taskId}`, data);
        if (res.errored){
            throw new Error("Error when updating task!");
        }

        return await res.json().then((data) => {
            return data.task;
        });
    });
}

export const useDeleteTask = () => {
    return useMutation('deleteTask', async (taskId) => {
        const res = await del(`tasks/${taskId}`);
        if (res.errored){
            throw new Error("Error when updating task!");
        }
        return true;
    });
}

export const useGetTasksByDate = (incompleteOnly) => {

    let url = "";

    if (incompleteOnly) {
        url = "tasks/getTasksForUserByDate/incomplete"; 
    }
    else {
        url = "tasks/getTasksForUserByDate";
    }

    return useQuery('getTasksByDate', async () => {

        let res = await get(url);

        if (res.errored){
            throw new Error("Error when getting tasks by date!!");
        }

        return await res.json().then((data) => {
            return data.tasksByDate;
        });
    });
}
