export function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
    }
    return str;
}

export function forceEllipsis(str, maxLength){
    if (str.length > maxLength){
        return str.slice(0, maxLength) + '...';
    }
    return str;
}


export function roundDollarAmount(amount) {
    if ((amount < 1 && amount > 0) || (amount > -1 && amount < 0)){
        return amount;
    }
    else if (amount === 0){
        return 0;
    }
    else {
        return Math.round(amount);
    }
}



// Method that waits until the user has completed inputting before firing the callback
// Especially useful for something like a search bar, where you may not want to fire the search functionality
// every single time the user presses a key, but instead wait until they are finished
export var waitUntilTypeComplete = function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    }
}();