import React, {useEffect, useRef, useState} from "react";
import "./Modals.css";
import { Dropdown } from 'flowbite-react';
import {Link} from "react-router-dom";
import "../Services/MiscAPI";
import {useCreateTask, useDeleteTask, useUpdateTask} from "../Services/TasksAPI";
import FormError from "../Components/Alerts/FormError";

export default function AddTaskModal(props){
    let colors = []
    colors = props.colors ? props.colors : colors;

    const [error, setError] = useState("")
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

    // Form Data
    let defaultFormData = {
        taskName: "",
        taskDescription: "",
        taskDeadlineDateUnix: "",
        taskDeadlineTime: "",
        taskDeadlineUnix: "",
        color: {
            colorId: "white",
            colorHexcode: "#FFFFFF",
            colorDisplayName: "White"
        },
        allDay: true
    }
    const [formData, setFormData] = useState(defaultFormData);
    const formRef = useRef(null);

    const clearForm = () => {
        setFormData(defaultFormData);
    }

    const validateAndGetTaskData = () => {
        if (!formData.taskName) { setError("You must give a task name!"); return false;}
        if (!formData.color) { setError("You must give a task color!"); return false;}
        if (!formData.taskDeadlineDateUnix) { setError("You must give a task deadline date!"); return false;}
        if (!formData.allDay && !formData.taskDeadlineTime) {
            setError("If task is not all day, you must specify a time!");
            return false;
        }

        // Calculate the task deadline unix
        let taskDeadlineUnix = (new Date(formData.taskDeadlineDateUnix));
        if (!formData.allDay) {
            let timeSplit = formData.taskDeadlineTime.split(":");
            taskDeadlineUnix.setTime(taskDeadlineUnix.getTime()
                + (((parseInt(timeSplit[0])) * 3600000))
                + ((parseInt(timeSplit[1])) * 60000));
        }

        return {
            taskName: formData.taskName,
            taskDescription: formData.taskDescription,
            taskAllDay: formData.allDay,
            colorId: formData.color.colorId,
            taskDeadlineUnix: taskDeadlineUnix.getTime()
        };
    }

    // POST - Create a new task
    const {mutate: createTask, status: createTaskStatus, isLoading: createTaskLoading,
        isSuccess: createTaskIsSuccess, isError: createTaskIsError} = useCreateTask();
    const addTask = async (e) => {
        e.preventDefault();

        if (props.editMode) { patchTask(e).then(); return; }

        if (!createTaskLoading && !updateTaskLoading){
            // Form Data validation
            let taskData = validateAndGetTaskData();
            if (taskData === false) { return; }
            createTask(taskData);
        }
    }
    // On Success createTask
    useEffect(() => {
        if (!createTaskLoading){
            onClose();
            props.refetchTasks();
        }
    }, [createTaskIsSuccess]);


    // PATCH - Update a task
    const {mutate: updateTask, status: updateTaskStatus, isLoading: updateTaskLoading,
        isSuccess: updateTaskIsSuccess, isError: updateTaskIsError} = useUpdateTask();
    const patchTask = async (e) => {
        e.preventDefault();

        if (!props.editMode) {return;}

        if (!updateTaskLoading && !createTaskLoading){
            // Form Data validation
            let taskData = validateAndGetTaskData();
            if (taskData === false) { return; }
            taskData.taskId = props.task.taskId;
            updateTask(taskData);
        }
    }
    // On Success UpdateTask
    useEffect(() => {
        if (!updateTaskLoading) {
            onClose();
            props.refetchTasks();
        }
    }, [updateTaskIsSuccess]);


    // DELETE - Delete a task
    const {mutate: deleteTask, status: deleteTaskStatus, isLoading: deleteTaskLoading,
        isSuccess: deleteTaskIsSuccess, isError: deleteTaskIsError} = useDeleteTask();
    const delTask = (e) => {
        if (!deleteTaskLoading && props.editMode){
            if (!props.task.taskId) { return; }
            deleteTask(props.task.taskId);
        }
    }
    // On Success DeleteTask
    useEffect(() => {
        if (!deleteTaskLoading) {
            onClose();
            props.refetchTasks();
        }
    }, [deleteTaskIsSuccess]);

    // On edit mode toggle on/off
    useEffect(() => {
        if (props.editMode){
            let t = props.task;
            if (!t) { return; }

            let dateObj = new Date(t?.taskDeadlineUnix);
            if (t?.taskAllDay) {  }
            let timezoneOffsetHours = dateObj.getTimezoneOffset() / 60;
            setFormData({
                taskName: t?.taskName,
                taskDescription: t?.taskDescription ? t.taskDescription : "",
                color: t?.colorObj,
                allDay: t?.taskAllDay,
                taskDeadlineDateUnix:
                    `${dateObj.getFullYear()}-${("0" + (dateObj.getUTCMonth() + 1)).slice(-2)}-${("0" + (dateObj.getUTCDate())).slice(-2)}`,
                taskDeadlineTime: t?.taskAllDay ? "" : `${("0"+((dateObj.getHours() + timezoneOffsetHours)%24)).slice(-2)}:${("0"+dateObj.getMinutes()).slice(-2)}`
            });
        }
    }, [props.editMode, props.task]);


    // The component of the Task Deadline date which includes the today button
    const TaskDeadlineDate = () => {
        let date = new Date();
        date.setTime(date.getTime() - (date.getTimezoneOffset() * 60000));
        let todayStr = date.toISOString().substring(0, 10);

        return <>
                <label htmlFor="task-deadline"
                       className="block mb-2 text-sm font-medium text-white">Deadline *</label>
            <div className={"flex justify-start items-center relative mb-3"}>
                <div className="relative  self-center">
                    <input type={"date"} id="task-deadline" style={{colorScheme: "dark"}}
                           value={formData.taskDeadlineDateUnix} required={true}
                           onChange={(e) => {setFormData({...formData, taskDeadlineDateUnix: e.target.value})}}
                           className="border text-sm rounded-lg
                               block w-full  p-2.5  bg-gray-700
                               border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500
                               focus:border-blue-500"/>
                </div>
                <button type={"button"} className={"bg-gray-700 px-2 py-1 rounded-md text-white self-center " +
                    "transition-colors hover:bg-gray-600 ml-3"}
                        onClick={() => setFormData({...formData, taskDeadlineDateUnix: todayStr})}>
                    Today
                </button>
            </div>
        </>
    }


    // On close modal
    const onClose = () => {
        clearForm();
        setError("");
        setIsConfirmingDelete(false);
        props.setEditMode(false);
        document.getElementById('add_task_modal').close()
    }

    return (
        <dialog id="add_task_modal"
                className={`modal modal-bottom sm:modal-middle bg-gray-800 text-white rounded-md w-auto md-1/3 
                    lg:1/2 xl:w-1/2`}>
            <div className={`modal-box px-10 py-8`}>
                { error && <FormError message={error}/>}
                <div className={"flex w-full"}>
                    <h3 className="font-bold w-36 text-lg modal-title mb-5">
                        { props.editMode
                            ? <>Edit Task</>
                            : <>Add Task</>
                        }
                    </h3>
                    <div className={"flex w-full justify-end"}>
                        { (props.editMode && !isConfirmingDelete) &&
                            <button type="button" onClick={() => {setIsConfirmingDelete(true);}}
                                    className="px-3 py-2 h-3/4 text-sm font-medium text-white
                                focus:ring-4 focus:outline-none rounded-lg text-center bg-red-500
                                hover:bg-red-400 focus:ring-red-800 flex">
                                <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
                                </svg>
                                &nbsp;Delete Task
                            </button>
                        }
                        { (props.editMode && isConfirmingDelete) &&
                            <div className={"inline md:flex justify-center items-center"}>
                                <p className={"self-center mr-2 w-full"}>Are you sure?</p>
                                <div className={"flex justify-center w-full pt-1"}>
                                    <button type="button" onClick={() => {setIsConfirmingDelete(false);}}
                                            className="mr-2 px-2 py-2 text-sm font-medium text-white
                                focus:ring-4 focus:outline-none  rounded-lg text-center bg-gray-500
                                hover:bg-gray-400 focus:ring-gray-800 flex">
                                        <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                                        </svg>
                                        <span className={"hidden md:block ml-1 pr-1"}>Cancel</span>
                                    </button>
                                    <button type="button" onClick={delTask}
                                            className="px-2 py-2 text-sm font-medium text-white
                                focus:ring-4 focus:outline-none rounded-lg text-center bg-red-500
                                hover:bg-red-400 focus:ring-red-800 flex justify-center text-center">
                                        <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clipRule="evenodd" />
                                        </svg>
                                        <span className={"hidden md:block ml-1 pr-1"}>Delete</span>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <form onSubmit={addTask} ref={formRef} onChange={()=>{setError("")}}
                    className={`${(createTaskLoading || updateTaskLoading || deleteTaskLoading) && "bg-opacity-40 opacity-40"}`}>
                    <div className={"form-input-group"}>
                        <label htmlFor="task-name"
                               className="block mb-2 text-sm font-medium text-white">Name *</label>
                        <div className="relative mb-6">
                            <input type="text" id="task-name" value={formData.taskName}
                                   onChange={(e) => {setFormData({...formData, taskName: e.target.value})}}
                                   required={true}
                                   className="border text-sm rounded-lg
                           block w-full p-2.5  bg-gray-700
                           border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500
                           focus:border-blue-500"
                                   placeholder="e.g. Read Chapter 1"/>
                        </div>
                    </div>



                    <div className={"form-input-group"}>
                        <label htmlFor="task-description"
                               className="block mb-2 text-sm font-medium text-white">Description</label>
                        <div className="relative mb-6">
                            <textarea id="task-description" value={formData.taskDescription}
                                    onChange={(e) => {setFormData({...formData, taskDescription: e.target.value})}}
                                    className="border text-sm rounded-lg
                           block w-full p-2.5  bg-gray-700
                           border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500
                           focus:border-blue-500"
                                   placeholder="e.g. Read chapter one to prepare for the quiz on Monday"/>
                        </div>
                    </div>



                    <div className={"form-input-group"}>
                        <TaskDeadlineDate/>

                        <div className="flex items-center mb-4">
                            <input id="task-all-day-checkbox" type="checkbox" value="" checked={formData.allDay}
                                   className="w-4 h-4 text-blue-600 rounded
                                   focus:ring-blue-600 ring-offset-gray-800 focus:ring-2 bg-gray-700
                                   border-gray-600" onChange={(e) =>
                                    {setFormData({...formData, allDay: e.target.checked})}}/>
                                <label htmlFor="task-all-day-checkbox"
                                       className="ml-2 text-sm font-medium text-gray-300">All Day</label>
                            { !formData.allDay &&
                                <>
                                    <input type={"time"} id="task-deadline-time" style={{colorScheme: "dark"}}
                                           value={formData.taskDeadlineTime}
                                           onChange={(e) => {setFormData({...formData, taskDeadlineTime: e.target.value})}}
                                           className=" text-gray-900 text-sm rounded-lg
                                    block w-3/8 md:w-1/4 ml-4 p-2.5 bg-gray-700
                                    border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500
                                    focus:border-blue-500"/>
                                    <label htmlFor={"task-deadline"} className={"ml-2"}>
                                        Time *
                                    </label>
                                </>
                            }
                        </div>
                    </div>


                    <div className={"form-input-group mt-5"}>
                        <label htmlFor="task-color"
                               className="block mb-2 text-sm font-medium text-white">Color *</label>
                        <Dropdown label={
                                <div className={"flex p-1"}>
                                <div className={"w-4 h-4 rounded-sm mr-2"}
                                     style={{backgroundColor: formData.color.colorHexcode}}/>
                                <p>{formData.color.colorDisplayName}</p>
                            </div>} class={"bg-gray-700 rounded-md"} id={"task-color-id"}>
                            {
                                colors.map((color) => {
                                    return (
                                        <div key={color.colorId} class={"bg-gray-800"}>
                                            <Dropdown.Item onClick={() => {setFormData({...formData, color})}}
                                                class={"bg-gray-700 px-3 py-2 text-sm w-full hover:bg-gray-600 text-white"}>
                                                <div className={"flex p-1 "}>
                                                    <div className={"w-4 h-4 rounded-sm mr-2"}
                                                         style={{backgroundColor: color.colorHexcode}}/>
                                                    <p>{color.colorDisplayName}</p>
                                                </div>
                                            </Dropdown.Item>
                                        </div>
                                    );
                                })
                            }
                        </Dropdown>
                    </div>
                </form>
                <div className="modal-action mt-6">
                    <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button onClick={onClose}
                            className="focus:outline-none text-white focus:ring-4
                                 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 bg-gray-600
                                hover:bg-gray-500 focus:ring-red-900">Cancel
                        </button>
                        { !props.editMode
                            ? <button onClick={addTask} type="button" disabled={createTaskLoading}
                                      className="px-5 py-2.5 text-sm font-medium text-white
                                focus:ring-4 focus:outline-none rounded-lg text-center bg-blue-600
                                hover:bg-blue-700 focus:ring-blue-800">Add
                            </button>

                            : <button type="button" onClick={patchTask} disabled={updateTaskLoading}
                                      className="px-5 py-2.5 text-sm font-medium text-white
                                focus:ring-4 focus:outline-none rounded-lg text-center bg-blue-600
                                hover:bg-blue-700 focus:ring-blue-800">Save
                            </button>
                        }
                    </form>
                </div>
            </div>
        </dialog>
    );
}