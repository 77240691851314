import React from "react";
import "./HomePage.css";
import John from "./ProfilePhotos/John.jpg";
import Camden from "./ProfilePhotos/Camden.jpg";
import Brandon from "./ProfilePhotos/Brandon.jpg";
import Jordan from "./ProfilePhotos/Jordan.jpg";
import Ifenna from "./ProfilePhotos/Ifenna.jpg";
import Eddie from "./ProfilePhotos/Eddie.jpg"
import Sean from "./ProfilePhotos/Sean.jpg"
export default function HomePage() {

    return <div>

            

            <nav class="z-50 fixed w-full border-gray-200 bg-gray-900">
                <div class="max-w-screen-xl flex flex-wrap items-center align-middle justify-between mx-auto p-4">
                    <a class="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src="/SB_PNG.png" className="h-12 rounded-full self-center mb-1" alt="StudBud Logo" />
                        <h1 className={"text-3xl md:text-4xl lg:text-4xl flex justify-center md:justify-start"}>
                            <span className={"name-stud text-blue-400 font-bold inline-block w-full"}>Stud</span>
                            <span className={"name-bud text-blue-800 font-bold inline-block w-full"}>Bud</span>
                        </h1>
                    </a>

                    <div class="md:block md:w-auto" id="navbar-cta">
                        <a href="/login" class="py-2 px-4 rounded text-xl text-white bg-blue-500 hover:bg-blue-400 transition-colors">Login</a>
                    </div>

                </div>
            </nav>


            
            <section class="bg-fixed bg-center bg-no-repeat bg-cover bg-[url('https://images.unsplash.com/photo-1565347878137-a5d5613ee61a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-[#131924] bg-blend-overlay grid grid-cols-3 grid-rows-1">

                <div className="py-10 pt-40 lg:pt-40 row-span-1 col-span-3 text-center">
                    <h1 class="mb-4 text-3xl font-extrabold tracking-tight leading-none text-white md:text-4xl lg:text-5xl">Welcome to StudBud</h1>
                    <p class="mb-10 mx-2 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">The only application you need for academic and financial success.</p>
                    
                    <div className="col-span-3 m-10">
                        <div className=" flex justify-center">

                            <div className={"w-full xl:w-3/4 grid grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-1"}>
                                <div
                                    className="bg-gray-700/95 h-auto text-white m-0 mb-5 md:m-5 rounded-md col-span-1 xs:row-span-1 slide-in-1">
                                    <div className="flex m-5 justify-center">
                                        <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                             className="w-12 h-12">
                                            <path
                                                d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z"/>
                                            <path fillRule="evenodd"
                                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z"
                                                  clipRule="evenodd"/>
                                        </svg>

                                    </div>
                                    <a>
                                        <h5 className="mb-2 mx-2 text-2xl font-semibold tracking-tight text-white">Track
                                            your budget</h5>
                                    </a>
                                    <p className="m-3 font-normal text-gray-400">Create a budget
                                        with <span className={"font-bold"}>your </span>
                                        desired goals. Visualize <span className={"font-bold"}>your</span> progress.
                                        Live <span className={"font-bold"}>your</span> dream.</p>
                                </div>

                                <div
                                    className="bg-gray-700/95 h-auto text-white m-0 mb-5 md:m-5 rounded-md col-span-1 xs:row-span-1 slide-in-2">
                                    <div className="flex m-5 justify-center">
                                        <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                             className="w-12 h-12">
                                            <path
                                                d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z"/>
                                            <path
                                                d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z"/>
                                        </svg>

                                    </div>
                                    <a>
                                        <h5 className="mb-2 mx-2 text-2xl font-semibold tracking-tight text-white">Manage
                                            your tasks</h5>
                                    </a>
                                    <p className="m-3 font-normal text-gray-400">Track your tasks to
                                        keep your life organized. <span className={"font-bold"}>Never</span> be
                                        surprised by a deadline again. </p>
                                </div>

                                <div
                                    className="bg-gray-700/95 h-auto text-white m-0 mb-5 md:m-5  rounded-md col-span-1 xs:row-span-1 slide-in-3">
                                    <div className="flex m-5 justify-center">
                                        <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                             className="w-12 h-12">
                                            <path
                                                d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z"/>
                                            <path
                                                d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z"/>
                                            <path
                                                d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z"/>
                                        </svg>

                                    </div>
                                    <a>
                                        <h5 className="mb-2 mx-2 text-2xl font-semibold tracking-tight text-white">Achieve
                                            your goals</h5>
                                    </a>
                                    <p className="m-3 font-normal text-gray-400">Being a student is
                                        <span className={"font-bold italic"}> stressful</span>. Live with a peace of
                                        mind knowing StudBud has your back.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"slide-in-up-4"}>
                        <a href="/create" className="text-white text-xl e rounded-md mb-5 py-3 px-5
                        w-5 hover:bg-blue-400 transition-colors bg-blue-500">Get Started</a>
                    </div>

                </div>

                                

                <div className="row-span-1 col-span-3 text-center mt-36 md:mt-60 lg:mt-80">
                    <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white">Vision Statement</h1>
                    <p class="mx-6 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">Welcome to StudBud, a one stop shop for tracking two of the most important aspects of life as a student, financial planning and time management. Using our budget, you are able to create your own categories of expenses, and allocate how much you would like to spend each month in those categories. We provide you with calculations and visual representations that make it simple and painless for you to keep your budget on track. Using our task planner, you can create tasks to represent homework assignments, classes, extracaricular activities, and anything else you need to remember! This color coded task organizer will ensure that you know what important events are approaching and that no deadlines sneak up on you. This handy tool was developed by students who understand the difficulties of keeping it all together while you go through college. Let StudBud guide you on your journey to success!</p>
                </div>

                
                <div className="row-span-7 col-span-3 row-span-1 mt-36 md:mt-60 lg:mt-80">
                    
                    <h1 class="mb-4 text-3xl font-extrabold text-white text-center sm:text-center">Meet the Team</h1>
                    
                    <div className="m-10 grid lg:grid-cols-3 lg:grid-rows-3 md:grid-cols-2 sm:grid-cols-1">

                        <a class="m-2 flex flex-col items-center border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl border-gray-700 bg-gray-800/80">
                            <img class="object-cover w-full rounded-t-lg h-30 md:h-full md:w-48 md:rounded-none md:rounded-s-lg" src={Camden} alt=""/>
                            <div class="flex flex-col justify-between p-4 leading-normal">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">Camden Link</h5>
                                    <p class="mb-3 font-normal text-gray-400">Computer Science Major at West Virginia University. Pursuing a career in Software Engineering. Has a Private Pilot's License as a hobby. Makes video games for fun.</p>
                            </div>
                        </a>

                        <a class="m-2 flex flex-col items-center border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl border-gray-700 bg-gray-800/80">
                            <img class="object-cover w-full rounded-t-lg h-30 md:h-full md:w-48 md:rounded-none md:rounded-s-lg" src={Jordan} alt=""/>
                            <div class="flex flex-col justify-between p-4 leading-normal">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">Jordan Dennison</h5>
                                <p class="mb-3 font-normal text-gray-400">Computer Science Major at West Virginia University. Interested in a career in software developement. Primary work on frontend developement.</p>
                            </div>
                        </a>

                        <a class="m-2 flex flex-col items-center border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl border-gray-700 bg-gray-800/80">
                            <img class="object-cover w-full rounded-t-lg h-30 md:h-full md:w-48 md:rounded-none md:rounded-s-lg" src={Eddie} alt=""/>
                            <div class="flex flex-col justify-between p-4 leading-normal">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">Eddie Porrini</h5>
                                <p class="mb-3 font-normal text-gray-400">Cyber Security Major at West Virginia University</p>
                            </div>
                        </a>

                        <a class="m-2 flex flex-col items-center border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl border-gray-700 bg-gray-800/80">
                            <img class="object-cover w-full rounded-t-lg h-30 md:h-full md:w-48 md:rounded-none md:rounded-s-lg" src={John} alt=""/>
                            <div class="flex flex-col justify-between p-4 leading-normal">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">John Craft</h5>
                                <p class="mb-3 font-normal text-gray-400">Computer Engineering Major at West Virginia University</p>
                            </div>
                        </a>

                        <a class="m-2 flex flex-col items-center border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl border-gray-700 bg-gray-800/80">
                            <img class="object-cover w-full rounded-t-lg h-30 md:h-full md:w-48 md:rounded-none md:rounded-s-lg" src={Sean} alt=""/>
                            <div class="flex flex-col justify-between p-4 leading-normal">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">Sean Sarley</h5>
                                <p class="mb-3 font-normal text-gray-400">Computer Science Major at West Virginia University</p>
                            </div>
                        </a>

                        <a class="m-2 flex flex-col items-center border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl border-gray-700 bg-gray-800/80">
                            <img class="object-cover w-full rounded-t-lg h-30 md:h-full md:w-48 md:rounded-none md:rounded-s-lg" src={Ifenna} alt=""/>
                            <div class="flex flex-col justify-between p-4 leading-normal">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">Ifenna Ekwenem</h5>
                                <p class="mb-3 font-normal text-gray-400">Computer Engineering Major at West Virginia University</p>
                            </div>
                        </a>

                        <a class="m-2 flex flex-col items-center lg:col-start-2 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl border-gray-700 bg-gray-800/80">
                            <img class="object-cover w-full rounded-t-lg h-30 md:h-full md:w-48 md:rounded-none md:rounded-s-lg" src={Brandon} alt=""/>
                            <div class="flex flex-col justify-between p-4 leading-normal">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">Brandon Johnson</h5>
                                <p class="mb-3 font-normal text-gray-400">Computer Engineering Major at West Virginia University</p>
                            </div>
                        </a>


                    </div> 
                </div>
                
            </section> 
    </div>
    
    
}