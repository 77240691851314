import React, {useEffect, useRef, useState} from "react";
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./ProgressCircle.css";

export default function ProgressCircle(props){
    let percent = props.percent ? props.percent : 0
    const [ringColor, setRingColor] = useState("#22c35d");

    const MAX_STROKE_DASH_ARRAY = 314.15926;

    useEffect(() => {
        if (percent <= 0){
            setRingColor("rgb(248 113 113)");
        }
        else {
            setRingColor(interpolate("rgb(248 113 113)", "#22c35d", (percent)));
        }
    }, [percent]);

    function interpolate(color1, color2, per) {
        per /= 100;

        if (per < 0.35 && per > 0.15) {
            color1 = "#d58532";
        }
        else if (per <= 0.15) {
            return color1;
        }
        else {
            return color2;
        }

        // Convert the hex colors to RGB values
        const r1 = parseInt(color1.substring(1, 3), 16);
        const g1 = parseInt(color1.substring(3, 5), 16);
        const b1 = parseInt(color1.substring(5, 7), 16);

        const r2 = parseInt(color2.substring(1, 3), 16);
        const g2 = parseInt(color2.substring(3, 5), 16);
        const b2 = parseInt(color2.substring(5, 7), 16);

        // Interpolate the RGB values
        const r = Math.round(r1 + (r2 - r1) * per);
        const g = Math.round(g1 + (g2 - g1) * per);
        const b = Math.round(b1 + (b2 - b1) * per);

        // Convert the interpolated RGB values back to a hex color
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }

    return (
        <div className={`self-center ${props.textSize ? props.textSize : "text-xl"}`} style={{width: props.width ? props.width : 120,
            height: props.height ? props.height : 120}}>
            { percent > 0
                ?  <CircularProgressbarWithChildren value={percent} styles={buildStyles({
                        textColor: "rgb(248 113 113)",
                        pathColor: ringColor,
                    })}>
                        <h1 className={"mt-1"} style={{color: "white"}}>{props.innerText1}</h1>
                        <p style={{color: "white"}}>{props.innerText2}</p>
                    </CircularProgressbarWithChildren>

                :  <CircularProgressbarWithChildren value={100} styles={buildStyles({
                        textColor: "white",
                        pathColor: ringColor,
                    })}>
                        <h1 className={"mt-1"} style={{color: "rgb(248 113 113)"}}>{props.innerText1}</h1>
                        <p style={{color: "rgb(248 113 113)"}}>{props.innerText2}</p>
                    </CircularProgressbarWithChildren>
            }

        </div>
    );
}