import React, {useEffect, useState} from "react";
import "./CreateAccountPage.css";
import {useAuth, useProvideAuth} from "../../Services/AuthContext";
import {useNavigate} from "react-router-dom";
import FormError from "../../Components/Alerts/FormError";
import FormWarning from "../../Components/Alerts/FormWarning";

export default function CreateAccountPage(){
    const {user, login, signup} = useAuth();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [message, setMessage] = useState(false);


    useEffect(() => {
        if (user){
            return navigate("/dashboard");
        }
    }, [user]);

    function handleCreate(event){
        event.preventDefault();
        if (!PasswordVerification()) return;
        setIsLoading(true);


        let data = new FormData(event.target);

        signup(data.get("email"), data.get("password")).then((signupRes) => {
            if (!signupRes.error){
                setIsLoading(false);
                return navigate("/dashboard");
            }
            else {
                if (signupRes.error === "CREATE-USER/EMAIL-TAKEN"){
                    setError("Email already taken!");
                    return
                }
                setError("Something went wrong! Please check the information below!")
            }
            setIsLoading(false);
        });
    }

    function PasswordVerification() {
        if (!password1 || !password2) { return false; }

        if (password1 === password2) {
            setMessage("");
            return true;
        } else {
            setMessage("Passwords do not match!");
            return false;
        }
    }

    function onPasswordChange(e) {
        setPassword1(e.target.value);
    }

    function onConfirmPasswordChange(e) {
        setPassword2(e.target.value);
    }

    useEffect(() => {
        PasswordVerification();
    }, [password1, password2])


    return (
        <div className="bg-gray-900 flex justify-center items-center h-screen">
            <div className="w-1/2 h-screen hidden lg:block">
                <img src="https://cdn.pixabay.com/photo/2016/02/07/21/03/computer-1185626_1280.jpg"
                     alt="Students studying" className="object-cover w-full h-full opacity-75
                     border-r-8 border-blue-300"/>
            </div>
            <div className="lg:p-36 md:p-52 sm:20 p-8 w-full lg:w-1/2">
                <div className={"flex justify-center h-full mb-24"}>
                    <h1 className={"text-6xl"}>
                        <span className={"name-stud text-blue-400 font-bold"}>Stud</span>
                        <span className={"name-bud text-blue-800 font-bold" }>Bud</span>
                    </h1>
                </div>

                <FormError message={error}/>

                { message &&
                    <FormWarning message={message}/>
                }

                <h1 className="text-2xl font-semibold text-blue-300 mb-4">Create Account</h1>
                <form onSubmit={handleCreate}>

                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-300">Email/Username</label>
                        <input type="email" id="email" name="email"
                               className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
                               autoComplete="off" required={true}/>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="password" className="block text-gray-300">Password</label>
                        <input type="password" id="password" name="password"
                               className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
                               autoComplete="off" required={true} onChange={onPasswordChange}/>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="confirmPassword" className="block text-gray-300">Re-enter Password</label>
                        <input type="password" id="confirmPassword" name="confirmPassword"
                               className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
                               autoComplete="off" required={true} onChange={onConfirmPasswordChange}/>
                    </div>

                    <div className="mb-4 flex items-center">
                        <input type="checkbox" id="remember" name="remember" className="text-blue-500"/>
                            <label htmlFor="remember" className="text-gray-300 ml-2">Remember Me</label>
                    </div>

                    <button type="submit"
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold
                            rounded-md py-2 px-4 w-full" disabled={isLoading}>Create
                    </button>
                </form>
            </div>
        </div>
    );
}