import React, {useEffect, useState} from "react";
import "./Budget.css";
import ProgressCircle from "../ProgressCircle/ProgressCircle";
import {useGetBudget, useGetBudgetByMonthYear, useGetCurrentBudget} from "../../Services/BudgetAPI";
import CenteredSpinner from "../CenteredSpinner/CenteredSpinner";
import {roundDollarAmount, waitUntilTypeComplete} from "../../utils";
import {findExpenseCategory} from "../../Services/APIParsers";
import EditBudgetModal from "../../Modals/EditBudgetModal";
import AddExpenseModal from "../../Modals/AddExpenseModal";
import {useDeleteExpense} from "../../Services/BudgetAPI";
import ConfirmModal from "../../Modals/ConfirmModal";
import ClockDisplay from "../ClockDisplay/ClockDisplay";
//Default Expense Function with proper imports
export default function Budget(props) {
    const [availableFunds, setAvailableFunds] = useState(0);
    const [expenseData, setExpenseData] = useState([]);
    const [tab, setTab] = useState("budget_view");
    const [deletingExpenseId, setDeletingExpenseId] = useState("");
    const [monthlyProgress, setMonthlyProgress] = useState(0);
    const selectedExpenses = [];


    // GET Budget hook
    const { data: budget, error: getBudgetError, isLoading: budgetIsLoading,
        refetch: refetchBudget, isRefetching: budgetRefetching} = useGetCurrentBudget();

    // DELETE - Delete a expense
    const {mutate: deleteExpense, status: deleteExpenseStatus, isLoading: deleteExpenseLoading,
        isSuccess: deleteExpenseIsSuccess, isError: deleteExpenseIsError} = useDeleteExpense();
    const onDelExpenseBtn = (expenseId) => {
        setDeletingExpenseId(expenseId);
        document.getElementById("delete-expense-confirm_modal").showModal();
    }
    const delExpense = () => {
        if (!deleteExpenseLoading){
            if (!deletingExpenseId) { return; }
            deleteExpense(deletingExpenseId);
        }
    }
    // On Success DeleteExpense
    useEffect(() => {
        if (!deleteExpenseLoading) {
            refetchBudget();
        }
    }, [deleteExpenseIsSuccess]);
    
        // Initial component load
    useEffect(() => {
        // Set the progress through the month
        var today = new Date();
        var d = new Date(today.getFullYear(), today.getMonth()+1, 0);
        setMonthlyProgress(Math.round(((today.getDate() - 1) / d.getDate()) * 100));
    }, []);



    // Expenses Search
    const [expensesSearchTerm, setExpensesSearchTerm] = useState("");
    let searchTimer = 0;
    const filteredExpenses = React.useMemo(() => {
        if (budgetIsLoading || !budget) { return []; }
        const filteredData = budget.expenses.filter((expense) => {

            let lowerSearchTerm = expensesSearchTerm.toLowerCase();
            let lowerDate = expense.expenseDate.toString().toLowerCase();
            let lowerDescription = expense.expenseDescription ? expense.expenseDescription.toString().toLowerCase() : "";

            // Expense Date (mm-dd-yyyy) and Description search
            if (lowerDate.includes(lowerSearchTerm) || (lowerDescription.includes(lowerSearchTerm)))
            {
                return expense;
            }

            // Expense Category name search
            let category = findExpenseCategory(expense.expenseCategoryId, budget.expenseCategories);
            if (category && category.expenseCategoryName.toString().toLowerCase().includes(lowerSearchTerm)){
                return expense;
            }

            // Expense Date by Full name
            // Ex: Sunday, October 15, 2023
            let split = lowerDate.split("-");
            if (split.length != 3) { throw new Error("Invalid Month Year Given!"); }
            let date = new Date(Date.UTC(parseInt(split[2]), parseInt(split[0]) - 1, parseInt(split[1]) + 1));
            let dateStr = date.toLocaleDateString("en-us",
                {weekday: "long", year: "numeric", day: "numeric", month: "long"}).toLowerCase();
            if (dateStr.includes(lowerSearchTerm)){
                return expense;
            }
        });
        return filteredData;
    }, [budget, expensesSearchTerm]);

    if (budgetIsLoading){
        return <CenteredSpinner/>
    }

    return (
        <div>
            <EditBudgetModal colors={props.colors} colorsObj={props.colorsObj} budget={budget} refetchBudget={refetchBudget}
                budgetIsLoading={budgetIsLoading}/>
            <ConfirmModal id={"delete-expense"} onConfirmCallback={delExpense} message={"Delete this expense? This action cannot be undone."}/>
            <div className={"w-full text-center mt-6"}>
                <ClockDisplay/>
            </div>
            <div className="border-b border-gray-700">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-400 items-center">
                    {/* <li className="mr-2">
                        <div
                            className={`inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg
                            hover:text-gray-300 hover:border-gray-300 group cursor-pointer
                            ${tab=="settings" ? "text-blue-400 border-b-2 border-blue-400 hover:text-blue-400 hover:border-blue-400" : "hover:border-gray-300 hover:text-gray-300"}`}
                            onClick={() => {}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-1">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                            </svg>
                            Add Expense
                        </div>
                    </li> */}
                    {/* <li className="mr-2">
                        <div
                            className={`inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg
                            hover:text-gray-300 hover:border-gray-300 group cursor-pointer
                            ${tab=="settings" ? "text-blue-400 border-b-2 border-blue-400 hover:text-blue-400 hover:border-blue-400" : "hover:border-gray-300 hover:text-gray-300"}`}
                            onClick={() => {}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-1">
                                <path fillRule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM9.75 17.25a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-.75zm2.25-3a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm3.75-1.5a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-5.25z" clipRule="evenodd" />
                                <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                            </svg>
                            History
                        </div>
                    </li> */}
                    <li className="mr-2">
                        <div
                           className={`inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg
                            hover:text-gray-300 hover:border-gray-300 group cursor-pointer
                            ${tab=="settings" ? "text-blue-400 border-b-2 border-blue-400 hover:text-blue-400 hover:border-blue-400" : "hover:border-gray-300 hover:text-gray-300"}`}
                            onClick={() => {document.getElementById('edit_budget_modal').showModal()}}>
                            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-1">
                                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                            </svg>
                            Edit Budget
                        </div>
                    </li>
                </ul>
            </div>


            { tab === "budget_view"
                ?
                <div class="p-5 pb-0 max-w grid gap-4 grid-rows-2 xs:grid-cols-2 xs:p-8 md:grid-cols-4 flex">
                    <div className="xs:col-span-2 md:col-span-2 h-full bg-gray-700 hover:bg-gray-600 text-white font-semibold rounded-md mb-5 pt-10 pb-0 px-10 w-25">
                        <div className={"w-full text-2xl md:text-3xl flex text-center justify-center"}>
                            <h1><b>This Month</b></h1>
                        </div>
                        <div className={"numeric-section text-left my-5"}>
                            <p className={"text-md font-bold mb-1"}>
                                <span className={`${budget.budgetRemainingTotal <= 0 && 
                                    "rounded-md bg-red-400 px-2 py-1"}`}><b>Available Balance</b></span></p>
                            <h1 className={`text-4xl ${budget.budgetRemainingTotal > 0 ? "text-green-500" :
                                "text-red-400 font-bold"}`}>
                                ${budget.budgetRemainingTotal}
                                <span style={{ fontSize: '21px', color: 'rgba(250, 249, 246, 0.7)' }}> / </span>
                                <span style={{ fontSize: '16px', color: 'rgba(250, 249, 246, 0.7)' }}>${budget.budgetProjectedTotal}</span>
                            </h1>
                        </div>
                        <div className={"numeric-section text-left my-5 mb-1"}>
                            <p className={"text-md font-bold"}><b>Spent this month</b></p>
                            <h1 className={"text-2xl text-red-400"}>${parseFloat(budget.budgetTotalSpent).toFixed(2)}</h1>
                        </div>

                        <div className={"mt-5"}>
                            <p className={"text-md font-bold mb-2"}><b>Month Progress</b></p>
                            <div className="w-full rounded-full bg-gray-800 dashed-line-bg">
                                <div
                                    className="bg-blue-500 text-md font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                                    style={{width: `${monthlyProgress}%`}}>{monthlyProgress}%
                                </div>
                            </div>
                        </div>
                        <div className={"mt-5"}>
                            <p className={"text-md font-bold mb-2"}><b>Spending Progress</b></p>
                            <div className="w-full rounded-full bg-gray-800 dashed-line-bg">
                                <div
                                    className={`text-md font-medium text-blue-100 text-center p-0.5 leading-none rounded-full ${budget.budgetSpendingProgressPercent > monthlyProgress ? "bg-red-400":"bg-green-600"}`}
                                    style={{ width: `${Math.min(budget.budgetSpendingProgressPercent, 100)}%` }}>{budget.budgetSpendingProgressPercent}%</div>
                            </div>
                        </div>

                        <div className={"mt-10 text-center"}>
                            { budget.budgetSpendPerDay >= 0 &&
                                <p className={"text-md mb-2 font-normal self-end"}><b>Spend
                                    <span className={"bg-blue-500 px-2 py-1 mx-1 font-bold rounded-md"}>
                                    ${budget.budgetSpendPerDay}</span>per day to stay on track</b></p>
                            }
                        </div>
                    </div>

                    <div className="xs:col-span-2 md:col-span-2 h-full bg-gray-700 hover:bg-gray-600 text-white
                    font-semibold text-center rounded-md mb-5 pt-10 md:py-10 px-2 md:px-5 w-25">
                        <div className={"w-full text-2xl md:text-3xl flex text-center mb-2 justify-center"}>
                            <h1><b>Spending</b></h1>
                        </div>


                        <div className={"grid grid-cols-2 grid-flow-row text-center"}>
                            { budget.expenseCategories.length <= 0

                                ? <div className={"opacity-50 self-center col-span-2 pt-36 items-center"}>
                                    <h1 className={" text-lg"}>No Spending Categories</h1>
                                    <p className={"text-md mt-3"}>Create them in
                                        <span className={"italic font-bold"}> "Budget Setup"</span></p>
                                </div>

                                : budget.expenseCategories.map((category) => {
                                    let remaining = roundDollarAmount(category.expenseCategoryGoalRemaining);
                                    return (
                                    <div className="my-3 mx-1 md:mx-0 text-base font-medium text-white items-center">
                                        <span className={`bg-red-500 px-4 py-1 rounded-md 
                                            ${category.expenseCategoryColorId === "white" && "text-gray-800"}`}
                                           style={{backgroundColor: props.colorsObj[category.expenseCategoryColorId].colorHexcode}}>
                                            {category.expenseCategoryName}</span>
                                        <div className={"flex justify-center w-full mt-2"}>
                                            <ProgressCircle percent={category.expenseCategoryPercentRemaining}
                                                innerText1={`$${Math.abs(remaining)}`}
                                                innerText2={`${remaining >= 0 ? "left" : "OVER"}`}/>
                                        </div>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                        
                
                    <AddExpenseModal budget={budget} refetchBudget={refetchBudget} colors={props.colors} colorsObj={props.colorsObj}/>
                    <div class="xs:col-span-2 md:col-span-4 row-span-4 h-full relative overflow-x-auto md:overflow-x-visible shadow-md sm:rounded-lg mt-10 mx-0 md:mx-10">
                        <div className={"inline md:flex items-center justify-between mb-5"}>
                            <div className={"flex"}>
                                <h1 className={"text-center text-3xl self-center font-bold mx-1 md:mx-2"}>Expenses</h1>
                                <button className="p-1 self-center bg-blue-500 ml-2 mt-1 rounded-md"
                                        onClick={() => {document.getElementById('add_expense_modal').showModal()}}>
                                    <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                </button>
                            </div>
                            <div className={"flex mt-2 mx-1 md:mx-2 text-sm md:text-base"}>
                                <div className="relative rounded-full bg-gray-700 w-full">
                                    <input
                                        type="text"
                                        placeholder="Category, description, or date"
                                        className="bg-transparent text-white pl-10 py-2 rounded-full w-full mr-10"
                                        onChange={(e) =>
                                            {
                                                clearTimeout(searchTimer);
                                                searchTimer = setTimeout(() => {setExpensesSearchTerm(e.target.value)}, 300);
                                            }
                                        }
                                    />
                                    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 absolute left-0 top-0 my-2 mx-3 text-gray-400">
                                        <path fillRule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        { selectedExpenses.length > 0
                            ? <button className={"bg-red-500 px-3 py-1 m-2 rounded-md flex"}>
                                <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-1">
                                    <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
                                </svg>
                                Delete Selected</button>
                            : <></>
                        }

                        <table class="w-full text-sm text-left text-gray-400 mt-3">
                            <thead class="text-xs text-gray-400 uppercase bg-gray-700">
                            <tr>
                                <th scope={"col"} className={"px-0 py-0"}/>
                                <th scope="col" className="px-2 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-2 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-2 py-3">
                                    Category
                                </th>
                                <th scope="col" class="px-2 py-3">
                                    Description
                                </th>


                            </tr>

                            </thead>
                            <tbody>

                            { budget.expenses.length <= 0 &&
                                <>
                                    <tr>
                                        <td className="px-5 py-4">
                                            <h1 className={"text-lg opacity-60 bg-transparent self-center"}>Expenses will appear here once they are created.</h1>
                                        </td>
                                    </tr>
                                </>
                            }

                            {
                            /**
                            This handles expenses. It finds the expense category and simply returns the color 
                            */
                                filteredExpenses.map((expense) => {
                                    let expenseCategory = findExpenseCategory(expense.expenseCategoryId, budget.expenseCategories);
                                    if (!expenseCategory) { return; }
                                    let color = props.colorsObj[expenseCategory.expenseCategoryColorId];
                                    return (
                                        <tr className="border-b bg-gray-900">
                                            <td className={""}>

                                                <button onClick={() => {onDelExpenseBtn(expense.expenseId);}}
                                                className={"w-full inline-flex self-center h-full justify-center items-center h-10"}>

                                                    <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 hover:bg-gray-800 rounded-md p-2 transition-colors">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>

                                                </button>

                                            </td>
                                            <td className="px-5 py-4">
                                                {expense.expenseDate}
                                            </td>
                                            <td className="px-2 py-4">
                                                <span className={"font-bold text-white"}>${expense.expenseAmount}</span>
                                            </td>
                                            <td className="px-2 py-4">
                                                <span  className={`px-3 py-1 rounded-lg ${expenseCategory.expenseCategoryColorId === "white" ? "text-gray-800" : "text-white"}`}
                                                    style={{backgroundColor: color.colorHexcode}}
                                                    >
                                                    {expenseCategory.expenseCategoryName}
                                                </span>
                                            </td>
                                            <td scope="row"
                                                className="px-2 py-4 font-medium whitespace-nowrap text-white">
                                                {expense.expenseDescription}
                                            </td>
                                        </tr>
                                    );
                                })
                            }

                            </tbody>
                        </table>
                    </div>
                </div>


                :

                <div className="grid flex grid-cols-1 grid-rows-6 p-5">
                    <div className="inline-flex row-span-1 bg-gray-700 hover:bg-gray-600 text-white font-semibold rounded-md mb-5 pt-10 pb-0 px-10">

                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.905 1.316 15.633 6M18 10h-5a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h5m0-5a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1m0-5V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1v-3m-6.367-9L7.905 1.316 2.352 6h9.281Z"/>
                        </svg>

                        &nbsp; Monthly Budget</div>

                    <div className="row-span-3 bg-gray-700 hover:bg-gray-600 text-white font-semibold rounded-md mb-5 pt-10 pb-0 px-10">
                        <div className="grid grid-rows-4">

                            <div className="row-span-1 inline-flex">

                            Category 1 &nbsp;

                            </div>

                            <div className="row-span-1 inline-flex">

                            Category 2 &nbsp;

                            </div>

                            <div className="row-span-1 inline-flex">

                            Category 3 &nbsp;

                            </div>

                            <div className="row-span-1 inline-flex">

                            Category 4 &nbsp;

                            </div>

                        </div>


                    </div>

                    <div className="row-span-1 bg-gray-700 hover:bg-gray-600 text-white font-semibold rounded-md mb-5 pt-10 pb-0 px-10">

                        Test 3

                    </div>

                </div>
            }
        </div>
    );
}
