import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Budget from "./Components/Budget/Budget";
import HomePage from "./Pages/Home/HomePage";
import DashboardPage from "./Pages/Dashboard/DashboardPage";
import LoginPage from "./Pages/Login/LoginPage";
import CreateAccountPage from "./Pages/CreateAccount/CreateAccountPage";
import {QueryClient, QueryClientProvider} from "react-query";
import {ProvideAuth, useAuth, useProvideAuth} from "./Services/AuthContext";
import {useEffect, useState} from "react";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";

// MAIN APP

function App() {
    const queryClient = new QueryClient();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <ProvideAuth>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/dashboard" element={
                            <ProtectedRoute>
                                <DashboardPage/>
                            </ProtectedRoute>
                        }/>
                        <Route path="/login" element={<LoginPage/>}></Route>
                        <Route path="/create" element={<CreateAccountPage/>}></Route>
                    </Routes>
                </Router>
            </QueryClientProvider>
        </ProvideAuth>
    );
}

export default App;
