import React, {useEffect, useRef, useState} from "react";
import FormError from "../Components/Alerts/FormError";
import {Dropdown} from "flowbite-react";
import {useDeleteTask, useUpdateTask} from "../Services/TasksAPI";
import {useCreateExpenseCategory, useDeleteExpenseCategory, useUpdateBudget} from "../Services/BudgetAPI";

export default function EditBudgetModal(props){
    let colors = props.colors ? props.colors : [];
    const defaultProjected = props.budgetProjectedTotal;

    const [error, setError] = useState("");
    // Form Data
    const defaultFormData = {
        expenseCategories: {},
        deletedCategories: [],
        budgetProjectedTotal: props.budget.budgetProjectedTotal
    }
    const [formData, setFormData] = useState(defaultFormData);
    const formRef = useRef(null);



    // VALIDATE FORM DATA, CHECK FOR ERRORS
    const validateFormData = () => {
        // Expense Categories
        for (const categoryId in formData.expenseCategories){
            const category = formData.expenseCategories[categoryId];
            if (!category.expenseCategoryColorId) {
                setError("Please select a valid color for expense categories!");
            }
            if (!category.expenseCategoryName){
                setError("Please enter a valid expense category name!");
            }
            if (category.expenseCategoryGoal < 0 || (category.expenseCategoryGoal !== 0 && !category.expenseCategoryGoal)){
                setError("Please enter a valid category goal!");
            }
        }
    }

    useEffect(() => {
        if (formData !== defaultFormData){
            validateFormData();
        }
    }, [formData]);

    // ON SUBMIT
    // PATCH - Update an expense category
    const {mutate: updateBudget, status: updateBudgetStatus, isLoading: updateBudgetLoading,
        isSuccess: updateBudgetIsSuccess, isError: updateBudgetIsError, error: updateBudgetError} = useUpdateBudget();
    const onUpdateBudget = (e) => {
        if (error) return;

        let budgetData = {
            budgetProjectedTotal: parseFloat(formData.budgetProjectedTotal),
            expenseCategories: formData.expenseCategories
        }

        // Delete the categories queued
        for (const i in formData.deletedCategories){
            const category = formData.deletedCategories[i];
            deleteExpenseCategory(category);
        }

        updateBudget({budgetId: props.budget.budgetId, budgetData});
    }
    useEffect(() => {
        if (updateBudgetError === null){
            if (!updateBudgetLoading && !deleteExpenseCategoryLoading && !createExpenseCategoryLoading){
                onClose();
                props.refetchBudget();
            }
        }
    }, [updateBudgetIsSuccess, updateBudgetError]);


    // ON CREATE CATEGORY
    // POST - Creates an expense category
    const {mutate: createExpenseCategory, status: createExpenseCategoryStatus, isLoading: createExpenseCategoryLoading,
        isSuccess: createExpenseCategoryIsSuccess, isError: createExpenseCategoryIsError} = useCreateExpenseCategory();
    const onCreateCategory = () => {
        let newCategory = createExpenseCategory({
            parentBudgetId: props.budget.budgetId,
            expenseCategoryName: `New`,
            expenseCategoryColorId: "white",
            expenseCategoryGoal: 1
        });
    }
    useEffect(() => {
        if (!updateBudgetLoading && !deleteExpenseCategoryLoading && !createExpenseCategoryLoading){
            props.refetchBudget();
        }
    }, [createExpenseCategoryIsSuccess]);

    // ON DELETE CATEGORY
    // DELETE - Deletes an expense category
    const {mutate: deleteExpenseCategory, status: deleteExpenseCategoryStatus, isLoading: deleteExpenseCategoryLoading,
        isSuccess: deleteExpenseCategoryIsSuccess, isError: deleteExpenseCategoryIsError} = useDeleteExpenseCategory();
    const onDeleteCategory = (expenseCategoryId) => {
        let categories = formData.expenseCategories;
        let deletedCats = formData.deletedCategories;
        delete categories[expenseCategoryId];
        deletedCats.push(expenseCategoryId);
        setFormData({
            ...formData,
            expenseCategories: categories,
            deletedCategories: deletedCats,
            budgetProjectedTotal: calculateTotalBudget()
        });
    }
    useEffect(() => {
        if (!updateBudgetLoading && !deleteExpenseCategoryLoading && !createExpenseCategoryLoading){
            props.refetchBudget();
        }
    }, [deleteExpenseCategoryIsSuccess]);


    // ON MODAL CLOSE
    const onClose = () => {
        setError("");
        document.getElementById('edit_budget_modal').close()
        resetForm();
    }


    // ON BUDGET DATA CHANGE
    useEffect(() => {
        resetForm();
    }, [props.budget]);


    // RESET AND REBUILD THE FORM DATA
    const resetForm = () => {
        if (props.budget && props.budget.expenseCategories){
            let expenseCategories = {};
            props.budget.expenseCategories.map((category) => {
                expenseCategories[category.expenseCategoryId] = {...category};
            });
            setFormData({...formData, expenseCategories, budgetProjectedTotal: calculateTotalBudget(expenseCategories),
                deletedCategories: [],
            });
        }
    }


    // ON PROJECTED TOTAL CHANGE
    const onProjectedTotalChange = (e) => {
        setFormData({...formData, budgetProjectedTotal: e.target.value});
    }

    // ON EXPENSE CATEGORY GOAL CHANGE
    const updateGoal = (expenseCategoryId, newValue) => {
        if (formData.expenseCategories[expenseCategoryId]){
            let categories = formData.expenseCategories;
            categories[expenseCategoryId].expenseCategoryGoal = newValue;
            setFormData({...formData, expenseCategories: categories,
                budgetProjectedTotal: calculateTotalBudget()})
        }
    }

    const calculateTotalBudget = (categories) => {
        let cats = categories;
        if (cats === undefined) { cats = formData.expenseCategories}

        let total = 0;
        Object.keys(cats).map((categoryId) => {
            let category = cats[categoryId];
            let value = parseFloat(category.expenseCategoryGoal);
            if (!isNaN(value)) {total += value;}
        });
        return total;
    }

    // ON EXPENSE CATEGORY COLOR CHANGE
    const updateColor = (expenseCategoryId, newColorId) => {
        if (formData.expenseCategories[expenseCategoryId]){
            let categories = formData.expenseCategories;
            categories[expenseCategoryId].expenseCategoryColorId = newColorId;
            setFormData({...formData, expenseCategories: categories});
        }
    }

    // ON EXPENSE CATEGORY NAME CHANGE
    const updateName = (expenseCategoryId, newName) => {
        if (formData.expenseCategories[expenseCategoryId]){
            let categories = formData.expenseCategories;
            categories[expenseCategoryId].expenseCategoryName = newName;
            setFormData({...formData, expenseCategories: categories});
        }
    }

    return (
        <dialog id="edit_budget_modal"
                className={`modal modal-bottom sm:modal-middle bg-gray-800 text-white rounded-md w-auto md-1/3 
                    lg:1/2 xl:w-1/2`}>
            <div className={`modal-box px-10 py-8`}>
                <div className={"flex w-full"}>
                    <h3 className="font-bold w-36 text-lg modal-title mb-5">Edit Budget</h3>
                </div>
                { error && <FormError message={error}/>}
                { updateBudgetIsError && <FormError message={updateBudgetError.message}/>}
                <form onSubmit={onUpdateBudget} ref={formRef} onChange={()=>{setError("")}}
                      className={`${(updateBudgetLoading || deleteExpenseCategoryLoading 
                          || createExpenseCategoryLoading || props.budgetIsLoading ) && "bg-opacity-40 opacity-40"}`}>


                    <div className={"text-xl font-bold mb-1"}>Total Monthly Budget</div>
                    <div className={"text-2xl mt-3 font-bold"}>
                        <span className={"p-2 rounded-md bg-gray-700"}>${formData.budgetProjectedTotal.toFixed(2)}</span>
                    </div>

                    <div className={"form-input-group mt-5"}>
                        <div className={"flex items-center justify-start align-middle mb-2"}>
                            <label htmlFor="input-group-1"
                                   className="self-end block mb-2 text-sm font-medium text-white">Expense Categories</label>
                            <button className={"mx-2 my-0 self-center"} type={"button"} onClick={() => {onCreateCategory()}}>
                                <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 rounded-md bg-blue-500 p-1 hover:bg-blue-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                                </svg>
                            </button>
                        </div>
                        <div>
                            { Object.values(formData.expenseCategories).map((category, i) => {
                                let categoryColor = props.colorsObj[formData.expenseCategories[category.expenseCategoryId].expenseCategoryColorId];
                                let percentage = (Math.round(formData.expenseCategories[category.expenseCategoryId].expenseCategoryGoal * 100 / formData.budgetProjectedTotal));
                                return (
                                    <>
                                        <div className={"flex"}>
                                            <input className={`font-bold text-xl my-1 px-3 py-1 rounded-md w-3/4 md:w-auto
                                            ${category.expenseCategoryColorId === "white" && "text-black"}`}
                                                   style={{backgroundColor: props.colorsObj[category.expenseCategoryColorId].colorHexcode}}
                                                   value={category.expenseCategoryName} onChange={(e) => {updateName(category.expenseCategoryId, e.target.value)}}>
                                            </input>
                                            <button className={"mx-2"} type={"button"} onClick={() => {onDeleteCategory(category.expenseCategoryId)}}>
                                                <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 rounded-md p-2 transition-colors opacity-50 hover:bg-gray-900">*/}
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />*/}
                                                </svg>
                                            </button>
                                        </div>

                                        <div className={"mb-10 mt-2 p-5 rounded-tr-md"}
                                             style={{borderLeft: "solid 7px gray", borderColor: props.colorsObj[category.expenseCategoryColorId].colorHexcode}}>
                                            <label htmlFor={`goal-input-${category.expenseCategoryId}`}
                                                   className="block mb-2 text-sm font-medium text-white">Goal {`(${isNaN(percentage) ? "--" : percentage}%)`}</label>
                                            <div className="relative mb-2 mt-1">
                                                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                                    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 opacity-50 mr-1">
                                                        <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                                                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                                <input type="number" className="border
                                             text-sm rounded-lg focus:border-blue-500 block ps-10 p-2.5
                                             bg-gray-700 border-gray-600 placeholder-gray-400 text-white
                                             focus:ring-blue-500 focus:border-blue-500 w-25"
                                                       placeholder="Goal ($)" id={`goal-input-${category.expenseCategoryId}`}
                                                value={formData.expenseCategories[category.expenseCategoryId].expenseCategoryGoal}
                                                onChange={(e) => {updateGoal(category.expenseCategoryId, e.target.value)}}/>
                                            </div>
                                            <div className={"form-input-group mt-1"}>
                                                <label htmlFor="task-color"
                                                       className="block mb-2 text-sm font-medium text-white">Color</label>
                                                <Dropdown label={
                                                    <div className={"bg-gray-700 flex p-1"}>
                                                        <div className={"w-4 h-4 rounded-sm mr-2"}
                                                             style={{backgroundColor: categoryColor.colorHexcode}}/>
                                                        <p>{categoryColor.colorDisplayName}</p>
                                                    </div>} class={"bg-gray-700 rounded-md"}>
                                                    {
                                                        colors.map((color) => {
                                                            return (
                                                                <div key={color.colorId}>
                                                                    <Dropdown.Item onClick={() => {updateColor(category.expenseCategoryId, color.colorId)}} 
                                                                    class={"bg-gray-700 px-3 py-2 text-sm w-full hover:bg-gray-600 text-white"}>
                                                                        <div className={"flex p-1"}>
                                                                            <div className={"w-4 h-4 rounded-sm mr-2"}
                                                                                 style={{backgroundColor: color.colorHexcode}}/>
                                                                            <p>{color.colorDisplayName}</p>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </Dropdown>
                                            </div>
                                        </div>

                                    </>
                                )
                            })

                            }
                        </div>
                    </div>
                </form>
                <div className="modal-action mt-6">
                    <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button onClick={onClose}
                                className="focus:outline-none text-white focus:ring-4
                                 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 bg-gray-600
                                hover:bg-gray-500 focus:ring-red-900">Cancel
                        </button>
                        <button type="button" onClick={onUpdateBudget}
                                className={`px-5 py-2.5 text-sm font-medium text-white
                                focus:ring-4 focus:outline-none rounded-lg text-center bg-blue-600
                                hover:bg-blue-700 focus:ring-blue-800 ${error && "opacity-50"}`}>Save
                        </button>
                    </form>
                </div>
            </div>
        </dialog>
    );
}