import React, {useEffect, useRef, useState} from "react";
import "./LoginPage.css";
import {useAuth, useProvideAuth} from "../../Services/AuthContext";
import {useNavigate} from "react-router-dom";
import FormError from "../../Components/Alerts/FormError";

export default function LoginPage(){
    const {user, login} = useAuth();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const rememberMe = useRef(false);

    useEffect(() => {
        if (user){
            return navigate("/dashboard");
        }
    }, [user]);

    function handleLogin(event){
        event.preventDefault();
        setIsLoading(true);
        let data = new FormData(event.target);
        login(data.get("email"), data.get("password"),
            rememberMe.current).then((success) => {
            if (success){
                setIsLoading(false);
            }
            else {
                setError("Invalid login credentials!")
            }
            setIsLoading(false);
        });
    }

    return (
        <div className="bg-gray-900 flex justify-center items-center h-screen md:h-full">
            <div className="w-1/2 h-screen hidden lg:block">
                <img src="https://cdn.pixabay.com/photo/2016/02/07/21/03/computer-1185626_1280.jpg"
                     alt="Students studying" className="object-cover w-full h-full opacity-75
                     border-r-8 border-blue-300"/>
            </div>
            <div className="lg:p-36 md:px-52 p-8 w-full lg:w-1/2">
                <div className={"flex justify-center h-full mb-24"}>
                    <h1 className={"text-6xl"}>
                        <span className={"name-stud text-blue-400 font-bold"}>Stud</span>
                        <span className={"name-bud text-blue-800 font-bold" }>Bud</span>
                    </h1>
                </div>

                <FormError message={error}/>
                <h1 className="text-2xl font-semibold text-blue-300 mb-4">Login</h1>
                <form onSubmit={handleLogin}>

                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-300">Email/Username</label>
                        <input type="email" id="email" name="email"
                               className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
                               autoComplete="off" required={true}/>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="password" className="block text-gray-300">Password</label>
                        <input type="password" id="password" name="password"
                               className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
                               autoComplete="off" required={true}/>
                    </div>

                    <div className="mb-4 flex items-center">
                        <input type="checkbox" id="remember" name="remember"
                               className="text-blue-500"
                            onChange={(e) => console.log(rememberMe.current = e.target.checked)}/>
                            <label htmlFor="remember" className="text-gray-300 ml-2">Remember Me</label>
                    </div>

                    <button type="submit"
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold
                            rounded-md py-2 px-4 w-full" disabled={isLoading}>Login
                    </button>
                </form>

                <div className="mt-6 text-blue-400 text-center">
                    <a href="/create" className="hover:underline">Sign up Here</a>
                </div>
            </div>
        </div>
    );
}