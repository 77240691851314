// Jordan Dennison
// Ifenna Ekwenem 
import React, {useEffect, useState} from "react";
import "./DashboardPage.css";
import Budget from "../../Components/Budget/Budget";
import Calendar from "../../Components/Calendar/Calendar";
import {useAuth} from "../../Services/AuthContext";
import {useNavigate} from "react-router-dom";
import {getAllColors} from "../../Services/MiscAPI";
import SpendingHistory from "../../Components/Spending History/SpendingHistory";
import CenteredSpinner from "../../Components/CenteredSpinner/CenteredSpinner";


export default function DashboardPage() {

    const [currentTab, setCurrentTab] = useState("budget");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const navigate = useNavigate();
    const {user, logout} = useAuth();
    const [colors, setColors] = useState([]);
    const [colorsObj, setColorsObj] = useState({});

    function handleLogout(){
        if (isLoading) return;

        setIsLoggingOut(true);
        setIsLoading(true);
        logout().then((data) => {
            setIsLoading(false);
            setIsLoggingOut(false);
        });
    }


    // On initial dashboard load
    useEffect(() => {
        getAllColors().then((colors) => {
            setColors(colors);
        });
    }, []);

    useEffect(() => {
        let newColorsObj = {};
        colors.forEach((color) => {
            newColorsObj[color.colorId] = color;
        });
        setColorsObj(newColorsObj);
    }, [colors]);



    // The GetContent function  determines what content to display based on the value of the currentTab variable.
    function GetContent(){
        if (currentTab == "dashboard"){
           // 
            return <div>
                            <div class="xs:col-span-1 md:col-span-3 text-3xl flex justify-center text-blue-400 font-bold py-5"><h1>Dashboard</h1>
                            </div>

                            <div class="p-2 max-w grid gap-4 grid-rows-6 xs:grid-cols-1 xs:p-8 md:grid-cols-3 flex items-center mx-5">
        
                            <div className="xs:col-span-1 bg-blue-700 hover:bg-blue-600 text-white font-semibold text-center rounded-md mb-5 py-10 px-10 w-25">
                                Recent Activity
                            </div>
                            
                            <div className="xs:col-span-1 bg-blue-700 hover:bg-blue-600 text-white font-semibold text-center rounded-md mb-5 py-10 px-10 w-25" onClick={() => {setCurrentTab("budget");}}>
                                  Weekly Spending                              
                            </div>
                            
                            <div className="xs:col-span-1 bg-blue-700 hover:bg-blue-600 text-white font-semibold text-center rounded-md mb-5 py-10 px-10 w-25" onClick={() => {setCurrentTab("budget");}}>
                                Remaining Budget
                            </div>
                            

                            <div className="xs:col-span-1 md:col-span-3 row-span-3 h-full bg-blue-500 text-white font-semibold text-center rounded-md mb-5 py-10 px-10 w-25">
                                In Depth Overview

                                

                            </div>
                            
                    </div>
                </div>
        }
        else if (currentTab == "budget") {
            if (colors.length === 0 || Object.keys(colorsObj).length === 0) {return <CenteredSpinner/> }
            return <Budget colors={colors} colorsObj={colorsObj}/>
        }
        else if (currentTab == "calendar"){
            return <Calendar colors={colors}/>
        }
        else if (currentTab == "spending-history"){
            return <SpendingHistory colorsObj={colorsObj}/>
        }
    }
// return statement, contains conditional checks for the tab options ("dashboard", "budget", "calendar", and "about_us")


// Renders a grid with multiple elements, including a "Dashboard" header and three blocks for "Recent Activity," "Weekly Spending," and "Remaining Budget." 
// There's also a larger block labeled "In-Depth Overview."
    return (
        <div className="grid grid-cols-6 bg-gray-900">
            <div className="col-span-1 bg-gray-950 text-white pt-5">
                <div className={"text-start flex justify-center"}>
                    <h1 className={"text-5xl flex justify-center md:justify-start"}>
                        <span className={"name-stud text-blue-400 font-bold hidden lg:block inline-block w-full"}>Stud</span>
                        <span className={"name-bud text-blue-800 font-bold hidden lg:block inline-block w-full"}>Bud</span>
                        <span className={"text-blue-400 text-4xl font-bold block lg:hidden"}>
                            S<span className={"text-blue-800"}>
                            B
                            </span>
                        </span>
                    </h1>
                </div>

                <ul className={"text-center w-full flex justify-center"}>
                    <div className={"mt-10 text-center"}>
                        {/*<li className={"w-full justify-center flex"}>*/}
                        {/*    <div className={`${currentTab === "dashboard" ? "bg-blue-800": "bg-gray-950 hover:bg-gray-800"} text-white font-semibold rounded-md mb-5 py-2 lg:justify-start sm:justify-center px-2 md:px-4 w-auto md:w-full flex cursor-pointer`} onClick={() => {setCurrentTab("dashboard");}}>*/}
                        {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 opacity-50 m-0 md:mr-2">*/}
                        {/*            <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />*/}
                        {/*            <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />*/}
                        {/*        </svg>*/}
                        {/*        <span className={"hidden lg:block"}>Dashboard</span>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        <li className={"w-full justify-center flex"}>
                            <div className={`${currentTab === "budget" ? "bg-blue-800": "bg-gray-950 hover:bg-gray-800"} text-white font-semibold rounded-md mb-5 py-2 lg:justify-start sm:justify-center px-2 md:px-4 w-auto md:w-full flex cursor-pointer`} onClick={() => {setCurrentTab("budget");}}>
                                <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 opacity-50 m-0 md:mr-2">
                                    <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd" />
                                </svg>

                                <span className={"hidden lg:block"}>Budget</span>
                            </div>
                        </li>
                        <li className={"w-full justify-start flex"}>
                            <div className={`${currentTab === "calendar" ? "bg-blue-800": "bg-gray-950 hover:bg-gray-800"} text-white font-semibold rounded-md mb-5 py-2 lg:justify-start sm:justify-center px-2 md:px-4 w-auto md:w-full  flex cursor-pointer`} onClick={() => {setCurrentTab("calendar");}}>
                                <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 opacity-50 m-0 md:mr-2">
                                    <path fillRule="evenodd" d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z" clipRule="evenodd" />
                                    <path fillRule="evenodd" d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zm9.586 4.594a.75.75 0 00-1.172-.938l-2.476 3.096-.908-.907a.75.75 0 00-1.06 1.06l1.5 1.5a.75.75 0 001.116-.062l3-3.75z" clipRule="evenodd" />
                                </svg>

                                <span className={"hidden lg:block"}>Tasks</span>
                            </div>
                        </li>
                        <li className={"w-full justify-start flex"}>
                            <div className={`${currentTab === "spending-history" ? "bg-blue-800": "bg-gray-950 hover:bg-gray-800"} text-white font-semibold rounded-md mb-5 py-2 lg:justify-start sm:justify-center px-2 md:px-4 w-auto md:w-full  flex cursor-pointer`} onClick={() => {setCurrentTab("spending-history");}}>
                                <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 opacity-50 m-0 md:mr-2">
                                    <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
                                </svg>


                                <span className={"hidden lg:block"}>Spending History</span>
                            </div>
                        </li>
                        <li className={"w-full justify-start flex"}>
                            <div className="bg-gray-950 hover:bg-gray-800 text-white font-semibold rounded-md mb-5 py-2 lg:justify-start sm:justify-center px-2 md:px-4 w-auto md:w-full flex cursor-pointer" onClick={handleLogout}>
                                <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 opacity-50 m-0 md:mr-2">
                                    <path fillRule="evenodd" d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm5.03 4.72a.75.75 0 010 1.06l-1.72 1.72h10.94a.75.75 0 010 1.5H10.81l1.72 1.72a.75.75 0 11-1.06 1.06l-3-3a.75.75 0 010-1.06l3-3a.75.75 0 011.06 0z" clipRule="evenodd" />
                                </svg>
                                <span className={"hidden lg:block"}>Logout</span>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>

            <div className={`h-screen overflow-y-scroll overflow-x-hidden col-span-5 flow-1 
                bg-gray-900 text-white ${isLoggingOut && "opacity-30"}`}>
                 {GetContent()}
            </div>
        </div>
    )
}