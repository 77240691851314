import React, {useEffect, useRef, useState} from "react";
import "./Calendar.css";
import TaskCard from "../TaskCard/TaskCard";
import AddTaskModal from "../../Modals/AddTaskModal";
import {useCreateTask, useGetTasksByDate, useUpdateTask} from "../../Services/TasksAPI";
import { Dropdown } from 'flowbite-react';
import CenteredSpinner from "../CenteredSpinner/CenteredSpinner";
import {useFetcher} from "react-router-dom";
import ClockDisplay from "../ClockDisplay/ClockDisplay";

export default function Calendar(props) {
    const [taskEditingObj, setTaskEditingObj] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [showAll, setshowAll] = useState(false);
    const todayHeaderRef = useRef(null);

    // Fetch Tasks hook
    const { data: tasksByDate, error: getTasksError, isLoading: tasksAreLoading,
        refetch: refetchTasks, isRefetching: tasksAreRefetching, status: tasksStatus} = useGetTasksByDate(!showAll);



    // PATCH - Update a task
    const {mutate: updateTask, status: updateTaskStatus, isLoading: updateTaskLoading,
        isSuccess: updateTaskIsSuccess, isError: updateTaskIsError} = useUpdateTask();
    const setTaskCompleteStatus = async (taskId, value) => {
        if (!updateTaskLoading){
            // Form Data validation
            updateTask({taskId, taskComplete: value});
        }
    }
    // On Success UpdateTask
    useEffect(() => {
        triggerRefetchTasks();
    }, [updateTaskIsSuccess]);

    function onShowAllChange(e) {
        console.log(e);
        setshowAll(e.target.checked);
    }

    useEffect(() => {
        triggerRefetchTasks()
    }, [showAll]);


    function triggerRefetchTasks() {
        if (!updateTaskLoading && !tasksAreRefetching){
            refetchTasks().then();
        }
    }

    // On component init
    const goToToday = () => {
        if (todayHeaderRef.current !== null){
            todayHeaderRef.current.scrollIntoView({block: "center", behavior: "smooth"});
        }
    }


    // Sort the tasks by date into four main categories
    // Overdue, today, tomorrow, future
    const SortedTasksByCategory = () => {
        let sorted = {
            overdue: [],
            today: [],
            tomorrow: [],
            complete: [],
            future: {}
        }

        let now = new Date();
        let todayNoTime = new Date();
        todayNoTime.setHours(0);
        todayNoTime.setMinutes(0);
        todayNoTime.setSeconds(0);

        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        // CHANGED MAP TO FOR EACH
        Object.keys(tasksByDate).forEach((key) => {
            let date = new Date(key);
            date.setTime(date.getTime() + (date.getTimezoneOffset() * 60000));

            // Overdue
            if (date.toDateString() !== now.toDateString() && date < todayNoTime){
                console.log(date + ' | ' + todayNoTime)

                Object.values(tasksByDate[key]).map((task) => {
                    if (task.taskComplete){
                        sorted.complete.push(task);
                    }
                    else {
                        sorted.overdue.push(task);
                    }
                })
            }

            // Today (and overdues only by time)
            else if (date.toDateString() === now.toDateString()){
                Object.values(tasksByDate[key]).map((task) => {
                    if (task.taskComplete){
                        sorted.complete.push(task);
                        return;
                    }

                    if (task.taskAllDay){
                        sorted.today.push(task);
                        return;
                    }

                    if (parseInt(task.taskDeadlineUnix) + new Date(task.taskDeadlineUnix).getTimezoneOffset() * 60000 < (now.getTime() - 60000)){
                        sorted.overdue.push(task);
                    }
                    else {
                        sorted.today.push(task);
                    }
                })
            }

            // Tomorrow
            else if (date.toDateString() === tomorrow.toDateString()){
                Object.values(tasksByDate[key]).map((task) => {
                    if (task.taskComplete){
                        sorted.complete.push(task);
                    }
                    else {
                        sorted.tomorrow.push(task);
                    }
                })
            }

            // Future
            else {
                let incompleteFutureOnly = []
                for (const i in tasksByDate[key]){
                    let task = tasksByDate[key][i];
                    if (!task.taskComplete){
                        incompleteFutureOnly.push(task);
                    }
                    else {
                        sorted.complete.push(task);
                    }
                }

                if (incompleteFutureOnly.length > 0){
                    sorted.future[key] = incompleteFutureOnly;
                }
            }
        });


        // Return the JSX
        return <div className={""}>

            {/* Complete */}
            { showAll &&
                <div className="w-full mb-10 grid flex justify-items-start gap-4">
                    <h1 className={`text-lg px-4 py-1 m-0 font-bold bg-green-600 rounded-md`}>Complete</h1>

                    { sorted.complete.length <= 0 &&
                        <p className={"opacity-60"}>Completed tasks will appear here.</p>
                    }

                    {sorted.complete.map((task) => {
                        return <TaskCard task={task}
                         onClick={() => {
                             setTaskEditingObj(task);
                             setEditMode(true);
                             document.getElementById('add_task_modal').showModal();
                         }}
                         onCompleteChange={(value) => {
                             setTaskCompleteStatus(task.taskId, value).then();
                             }}/>
                    })}
                </div>
            }

            {/* Overdue */}
            <div className="w-full mb-10 grid flex justify-items-start gap-4">
                <h1 className={`text-lg px-4 py-1 m-0 font-bold bg-red-500 rounded-md`}>Overdue</h1>

                { sorted.overdue.length <= 0 &&
                    <p className={"opacity-60"}>You are all caught up!</p>
                }

                {sorted.overdue.map((task) => {
                    return <TaskCard task={task}
                     onClick={() => {
                         setTaskEditingObj(task);
                         setEditMode(true);
                         document.getElementById('add_task_modal').showModal();
                     }}
                     onCompleteChange={(value) => {
                         setTaskCompleteStatus(task.taskId, value).then();
                     }}
                     overdue={true}/>
                })}
            </div>

            {/* Today */}
            <div className="w-full mb-10 grid flex justify-items-start gap-4">
                <h1 className={`text-lg px-4 py-1 font-bold bg-blue-500 rounded-md`}>Today</h1>

                { sorted.today.length <= 0 &&
                    <p className={"opacity-60"}>Nothing due today.</p>
                }

                {sorted.today.map((task) => {
                    return <TaskCard task={task}
                     onClick={() => {
                         setTaskEditingObj(task);
                         setEditMode(true);
                         document.getElementById('add_task_modal').showModal();
                     }}
                     onCompleteChange={(value) => {
                         setTaskCompleteStatus(task.taskId, value).then();
                     }}/>
                })}
            </div>

            {/* Tomorrow */}
            { sorted.tomorrow.length > 0 &&
                <div className="w-full mb-6 md:mb-8 grid flex justify-items-start gap-4">
                    <h1 className={`text-lg px-4 py-1 font-bold bg-blue-900 rounded-md`}>Tomorrow</h1>

                    {sorted.tomorrow.map((task) => {
                        return <TaskCard task={task}
                         onClick={() => {
                             setTaskEditingObj(task);
                             setEditMode(true);
                             document.getElementById('add_task_modal').showModal();
                         }}
                         onCompleteChange={(value) => {
                             setTaskCompleteStatus(task.taskId, value).then();
                         }}/>
                    })}
                </div>
            }

            {/* Future */}

            { Object.keys(sorted.future).length > 0 &&
                <div className={"w-full mb-6 md:0 grid flex justify-items-center gap-0"}>
                    <span className={"opacity-60 m-0"}>.</span>
                    <span className={"opacity-60 m-0"}>.</span>
                    <span className={"opacity-60 m-0"}>.</span>
                </div>
            }

            {Object.keys(sorted.future).map((date) => {
                let d = new Date(date);

                return (<div className="w-full mb-10 grid flex justify-items-start gap-4">
                    <h1 className={`text-lg p-0 m-0 font-bold`}>{d.toLocaleDateString('en-us', {weekday: "long", timeZone: "GMT"})}<span className={"mr-2"}>,</span>{
                        d.toLocaleDateString('en-us', {month:"short", day:"numeric", timeZone:"GMT"})}</h1>
                    <div className={"w-full h-0.5 bg-gray-700 opacity-75"}/>

                    {sorted.future[date].map((task) => {
                        return <TaskCard task={task}
                        onClick={() => {
                        setTaskEditingObj(task);
                        setEditMode(true);
                        document.getElementById('add_task_modal').showModal();
                    }}
                        onCompleteChange={(value) => {
                        setTaskCompleteStatus(task.taskId, value).then();
                    }}/>
                    })}

                </div>)
            })}
        </div>
    }



    if (tasksStatus === "loading") {
        return <CenteredSpinner/>
    }

    return (
        <div className={"pb-32 md:pb-0"}>
            <div className={"w-full flex justify-center"}>
                <div className={"calendar-clock-section self-center text-center mt-6 inline"}>
                    <ClockDisplay/>
                </div>
            </div>
            <div className={"task-tools absolute bottom-5 right-5 md:bottom-8 md:right-10 z-30"}>
                <button className="btn self-center p-3 text-xl rounded-md flex items-center bg-blue-500"
                        onClick={()=>document.getElementById('add_task_modal').showModal()}>

                    <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    &nbsp; New Task
                </button>
            </div>


            <div className={"task-tools absolute bottom-20 md:bottom-24 right-5 md:right-10 z-30"}>
                <div className={"bg-gray-700 rounded-md py-3 px-2 inline-flex items-center"}>
                    <label className="relative inline-flex items-center cursor-pointer ">
                        <input type="checkbox" value="" className="sr-only peer" onChange={onShowAllChange}/>
                        <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4
                            peer-focus:ring-blue-800 rounded-full peer bg-gray-800 peer-checked:after:translate-x-full
                            rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-['']
                            after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border
                             after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ms-3 text-sm font-medium text-gray-300 mr-1">Show All</span>
                    </label>
                </div>

               {/* <Dropdown label={<div>Filter</div>} className="" style={{padding: "0.25rem"}} placement="top">
                    <ul class="p-3 space-y-3 text-sm text-gray-200" aria-labelledby="dropdownCheckboxButton">
                        <li>
                            <div class="flex items-center">
                                <input checked={showAll} onChange={onShowAllChange} id="checkbox-item-1" type="checkbox" value="" class="w-4 h-4 text-blue-600 rounded focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500" />
                                    <label for="checkbox-item-1" class="ml-2 text-sm font-medium text-gray-300">Show All</label>
                            </div>
                        </li>
                    </ul>
                </Dropdown>*/}

            </div>

            <div className="my-10 mx-3 lg:mx-60">
                <AddTaskModal colors={props.colors} refetchTasks={triggerRefetchTasks}
                    editMode={editMode} task={taskEditingObj} setEditMode={setEditMode}/>
                { tasksAreLoading
                    ? <>
                        <div className={"my-6"}>
                            <TaskCard/>
                        </div>
                        <div className={"my-6"}>
                            <TaskCard/>
                        </div>
                        <div className={"my-6"}>
                            <TaskCard/>
                        </div>
                        <div className={"my-6"}>
                            <TaskCard/>
                        </div>
                        <div className={"my-6"}>
                            <TaskCard/>
                        </div>
                        <div className={"my-6"}>
                            <TaskCard/>
                        </div>
                        <div className={"my-6"}>
                            <TaskCard/>
                        </div>
                        <div className={"my-6"}>
                            <TaskCard/>
                        </div>
                        <div className={"my-6"}>
                            <TaskCard/>
                        </div>
                    </>
                    :
                    <>
                        { Object.keys(tasksByDate).length <= 0
                            ?
                            <div className={"w-full flex justify-center items-center h-screen"}>
                                <p className={"opacity-75"}>You have no tasks. Click the '+ New Task' to create one.</p>
                            </div>
                            :
                            <>
                                <SortedTasksByCategory/>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
}