import React, {useState} from "react";
import ClockDisplay from "../ClockDisplay/ClockDisplay";
import { v4 as uuid } from 'uuid';
import HistoryCard from "./HistoryCard";
import ProgressCircle from "../ProgressCircle/ProgressCircle";
import {useGetAllBudgets, useGetCurrentBudget} from "../../Services/BudgetAPI";
import {Spinner} from "flowbite-react";
import CenteredSpinner from "../CenteredSpinner/CenteredSpinner";


export default function SpendingHistory(props){
    const [expandedMonths, setExpandedMonths] = React.useState({});
    const [filter, setFilter] = React.useState('oldest');
    const [searchTerm, setSearchTerm] = React.useState('');
    const [expensesData, setExpensesData] = React.useState({
        "January 2023": { budget: 1250, expenses: [{ category: "Groceries", amount: 200 }, { category: "Rent", amount: 500 }, { category: "Entertainment", amount: 300 }] },
        "February 2023": { budget: 1500, expenses: [{ category: "Groceries", amount: 220 }, { category: "Rent", amount: 509 }, { category: "Utilities", amount: 20 }, { category: "Transportation", amount: 75 }] },
        // Additional months and years would be added here
    });


    // GET Budget hook
    const { data: budgets, error: getBudgetsError, isLoading: budgetsAreLoading,
        refetch: refetchBudgets, isRefetching: budgetsRefetching} = useGetAllBudgets();

    const toggleMonth = (month) => {
        setExpandedMonths(prev => ({ ...prev, [month]: !prev[month] }));
    };

    const expenseColor = (totalExpenses, totalBudget) => {
        return totalExpenses > totalBudget ? 'text-red-400' : 'text-green-500';
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    // Converts a raw month year like "10-2023" to "October 2023"
    const monthYearToString = (monthYear) => {
        let split = monthYear.split("-");
        if (split.length != 2) { throw new Error("Invalid Month Year Given!"); }
        let date = new Date(Date.UTC(parseInt(split[1]), parseInt(split[0]), 1));
        return date.toLocaleDateString("en-us", {month: "long", year: "numeric"});
    }

    const monthYearToDate = (monthYear) => {
        let split = monthYear.split("-");
        if (split.length != 2) { throw new Error("Invalid Month Year Given!"); }
        return new Date(Date.UTC(parseInt(split[1]), parseInt(split[0]), 1));
    }


    const filteredAndSortedData = React.useMemo(() => {
        if (budgetsAreLoading || !budgets) { return []; }

        const filteredData = budgets.filter((budget) => monthYearToString(budget.budgetMonthYear).toLowerCase().includes(searchTerm.toLowerCase()));
        return filteredData.sort((budgetA, budgetB) => {
            let dA = monthYearToDate(budgetA.budgetMonthYear);
            let dB = monthYearToDate(budgetB.budgetMonthYear)

            if (filter === 'newest') {
                if (dA > dB) {return 1;}
                else { return -1; }
            } else {
                if (dA > dB) {return -1;}
                else { return 1; }
            }
        });
    }, [budgets, searchTerm, filter]);


    if (budgetsAreLoading){
        return (
        <div className={"w-full flex justify-center h-full"}>
            <CenteredSpinner/>
        </div>)
    }

    return (
        <div className="bg-gray-900 min-h-screen p-4 mt-6 text-white w-full">
            <div className={"justify-items-center justify-center mx-2 lg:mx-24 xl:mx-36"}>
                <div className="inline md:flex justify-between items-center mb-4 self-center text-sm md:text-base">
                    <div
                        className="relative rounded-full bg-gray-700 px-6 py-2 w-32 md:w-1/4 lg:w-1/5 xl:w-1/6 my-3 lg:my-0 flex
                        items-center justify-between cursor-pointer"
                        onClick={() => handleFilterChange(filter === 'newest' ? 'oldest' : 'newest')}
                    >
                        <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path fillRule="evenodd" d="M2.25 4.5A.75.75 0 013 3.75h14.25a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75zm14.47 3.97a.75.75 0 011.06 0l3.75 3.75a.75.75 0 11-1.06 1.06L18 10.81V21a.75.75 0 01-1.5 0V10.81l-2.47 2.47a.75.75 0 11-1.06-1.06l3.75-3.75zM2.25 9A.75.75 0 013 8.25h9.75a.75.75 0 010 1.5H3A.75.75 0 012.25 9zm0 4.5a.75.75 0 01.75-.75h5.25a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                        </svg>

                        <span>{filter === 'newest' ? 'Oldest' : 'Newest'}</span>
                    </div>
                    <div className="relative rounded-full bg-gray-700 w-full md:w-1/2 xl:w-1/3 my-3">
                        <input
                            type="text"
                            placeholder="Search month or year"
                            className="bg-transparent text-white pl-10 py-2 rounded-full w-full"
                            onChange={handleSearchChange}
                        />
                        <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 absolute left-0 top-0 my-1.5 mx-3 text-gray-400">
                            <path fillRule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <hr className={"md:hidden w-full opacity-50 my-4"}/>
                </div>

                { budgets.length <= 0 &&
                    <div className={"w-full flex justify-center mt-12"}>
                        <p className={"text-lg opacity-60"}>Budget history will appear here.</p>
                    </div>
                }

                { filteredAndSortedData.length <= 0 &&
                    <div className={"w-full flex justify-center mt-12"}>
                        <p className={"text-lg opacity-60"}>No results found.</p>
                    </div>
                }

                {filteredAndSortedData.map((budget) => {
                    const isExpanded = expandedMonths[monthYearToString(budget.budgetMonthYear)];
                    const expenseCategories = budget.expenseCategories;
                    return (
                        <div key={monthYearToString(budget.budgetMonthYear)} className="mb-6">
                            <div
                                className="bg-gray-700 p-4 rounded-md flex justify-center items-center cursor-pointer"
                                onClick={() => toggleMonth(monthYearToString(budget.budgetMonthYear))}>
                                <h2 className="text-sm md:text-xl font-bold mr-5">{monthYearToString(budget.budgetMonthYear)}</h2>
                                <div className="flex items-center">
                                    <span className={`${expenseColor(budget.budgetTotalSpent, budget.budgetProjectedTotal)} font-semibold text-xs mr-1 md:text-xl`}>${budget.budgetTotalSpent}</span>
                                    <span className="text-white font-semibold text-xs md:text-xl ml-0 mr-2 md:mr-5">/ ${budget.budgetProjectedTotal}</span>
                                    <svg xmlns="https://www.w3.org/2000/svg" className="h-6 w-6 transform transition-transform delay-0" style={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                            <div className={`${isExpanded ? 'max-h-screen' : 'max-h-0'} overflow-hidden transition-max-height duration-500 ease-in-out`}>


                                <p className={"text-lg md:text-xl border-b-2 border-gray-500 font-bold py-3 px-6 text-center bg-gray-800"}>You had
                                    <span className={"rounded-md bg-blue-600 px-2 mx-2"}>{budget.expenses.length}</span>
                                    total expenses.
                                </p>
                                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 p-2 bg-gray-800 rounded-b-md">


                                    {expenseCategories.map((category, index) => (
                                        <div key={index} className="my-3 mx-1 md:mx-0 text-base justify-center text-center font-medium text-white items-center">
                                             <span className={`bg-red-500 px-4 py-1 rounded-md 
                                            ${category.expenseCategoryColorId === "white" && "text-gray-800"}`}
                                                   style={{backgroundColor: props.colorsObj[category.expenseCategoryColorId].colorHexcode}}>
                                            {category.expenseCategoryName}</span>
                                            <div className={"flex justify-center w-full mt-2"}>
                                                <ProgressCircle percent={category.expenseCategoryPercentRemaining}
                                                innerText1={`$${(Math.abs(category.expenseCategoryGoalRemaining)).toFixed(2)}`}
                                                innerText2={`${(category.expenseCategoryGoalRemaining).toFixed(2) >= 0 ? "saved" : "OVER"}`}
                                                width={110} height={110} textSize={"text-base"}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
