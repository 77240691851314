import React from "react";
import FormError from "../Components/Alerts/FormError";
import {Dropdown} from "flowbite-react";

// Export the confirm modal component
export default function ConfirmModal(props){

    // On close state, close the modal
    const onClose = () => {
        document.getElementById(`${props.id}-confirm_modal`).close();
    }

    return (

        // PROMPT USER TO CONFIRM WHETHER OR NOT THEY WOULD LIKE TO COMPLETE THE ACTION IN QUESTION
        <dialog id={`${props.id}-confirm_modal`}
                className={`modal modal-bottom sm:modal-middle bg-gray-800 text-white rounded-md w-auto md-1/3 
                    lg:1/4 xl:w-1/4`}>
            <div className={`modal-box px-10 py-8`}>
                <div className={"flex w-full"}>
                    <h3 className="font-bold w-36 text-lg modal-title mb-2">Are you sure?</h3>
                </div>

                <p className={"my-2 font-normal text-md"}>{props.message}</p>

                <div className="modal-action mt-6">
                    <form method="dialog">

                        {/* BUTTON TO CANCEL THE ACTION ATTEMPTING TO BE PERFORMED*/}
                        <button onClick={onClose}
                                className="focus:outline-none text-white focus:ring-4
                                 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 bg-gray-600
                                hover:bg-gray-500 focus:ring-red-900">Cancel
                        </button>

                        {/* BUTTON TO CONFIRM THE USER WANTS TO COMPLETE THE ACTION */}
                        <button type="button" onClick={() => {props.onConfirmCallback(); onClose()}}
                                className="px-5 py-2.5 text-sm font-medium text-white
                                focus:ring-4 focus:outline-none rounded-lg text-center bg-blue-600
                                hover:bg-blue-700 focus:ring-blue-800">Confirm
                        </button>
                    </form>
                </div>
            </div>
        </dialog>
    );
}