import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../Services/AuthContext";

export default function ProtectedRoute(props){
    const navigate = useNavigate();
    const {user} = useAuth();

    if (!user){
        navigate("/login");
        return;
    }
    return props.children
}