import React, {useEffect, useState} from "react";
import "./TaskCard.css";
import {forceEllipsis} from "../../utils";

export default function TaskCard(props) {
    let task = props.task;

    const [taskComplete, setTaskComplete] = useState((props.task ? props.task.taskComplete : false));

    const onClick = () => {
        if (props.onClick) { props.onClick(); }
    }

    const onCompleteChange = (e) => {
        if (props.onCompleteChange) { props.onCompleteChange(e.target.checked); }

    }

    useEffect(() => {
        if(props.task){
            setTaskComplete(props.task.taskComplete)
        }
    }, [props.task])

    if (!task){
        return (
            <div className="w-full p-1 border rounded-lg shadow
                bg-gray-800 border-gray-700 hover:bg-gray-700">
                <div className={"grid grid-cols-12 grid-rows-2"}>
                    <div className="task-color-tag h-16 rounded-lg col-span-1 row-span-2 animate-pulse bg-gray-600"></div>
                    <div className={"col-span-8 row-span-2 animate-pulse"}>
                        <h1 className="mb-2 text-md md:text-xl font-bold tracking-tight animate-pulse text-white ">
                            </h1>
                        <h5 className="mb-2 text-sm md:text-lg opacity-75 tracking-tight animate-pulse text-white ">
                            </h5>
                    </div>
                    <div className={"col-span-3 row-span-2 flex items-center justify-start md:justify-center"}>
                        <p className={"text-sm md:text-lg tracking-tight text-white " +
                            "py-1 px-2 shadow-md no-underline rounded-md bg-gray-600 animate-pulse"}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (  
        <div className={`w-full p-1 border rounded-lg shadow transition-colors
            bg-gray-800 ${props.overdue ? "hover:bg-red-400" : "hover:bg-gray-700 "} hover:cursor-pointer 
            ${taskComplete && "opacity-50"} ${props.overdue && "bg-red-500"}`}
             style={{borderColor: `${props.overdue ? "rgb(239 68 68)" : task.colorObj.colorHexcode}`}}>
            <div className={`grid grid-cols-12 grid-rows-2`}>
                <div className="task-color-tag h-full rounded-lg col-span-1 row-span-2"
                     style={{backgroundColor: `${props.overdue ? "transparent" : task.colorObj.colorHexcode}`}} onClick={onClick}></div>
                <div className={"col-span-6 md:col-span-8 row-span-2"} onClick={onClick}>
                    <h1 className="mb-2 text-md md:text-xl font-bold tracking-tight text-white">
                        {task.taskName}</h1>
                    <h5 className="mb-2 mr-1 text-sm md:text-lg opacity-75 tracking-tight text-white
                        break-words">
                        {task.taskDescription ? forceEllipsis(task.taskDescription, 50) : "No Description"}</h5>
                </div>
                <div className={"col-span-5 md:col-span-3 row-span-2 flex items-center justify-end md:justify-end"}>
                    <p className={"text-sm md:text-lg tracking-tight text-white " +
                        "py-1 px-1 md:px-2 shadow-md no-underline rounded-md bg-gray-600 whitespace-nowrap"} onClick={onClick}>
                        {task.taskAllDay ? "All Day" : new Date(task.taskDeadlineUnix)
                            .toLocaleTimeString("en-us", {hour12: true, hour: "2-digit", minute: "2-digit",
                                timeZone: "GMT",})
                        }
                    </p>
                    <div className="flex items-center ml-5">
                        <input id="checked-checkbox" type="checkbox" value="" onChange={(e) => {setTaskComplete(e.target.checked);
                            onCompleteChange(e);}}
                               checked={taskComplete}
                               className="w-7 h-7 z-20 mr-0 md:mr-3 text-blue-600 rounded 
                               focus:ring-blue-600 ring-offset-gray-800 focus:ring-2 bg-gray-700
                               border-gray-600 "/>
                            <label htmlFor="checked-checkbox"
                                   className="ml-2 text-sm font-medium text-gray-300"></label>
                    </div>
                </div>
            </div>
        </div>
    );
}