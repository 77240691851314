import React, {useEffect, useRef, useState} from "react";
import FormError from "../Components/Alerts/FormError";
import {Dropdown} from "flowbite-react";
import {useUpdateTask} from "../Services/TasksAPI";
import {useCreateExpense, useCreateExpenseCategory, useUpdateBudget} from "../Services/BudgetAPI";

export default function AddExpenseModal(props){
    let budget = props.budget ? props.budget : [];
    //const defaultProjected = props.budgetProjectedTotal;

    const [error, setError] = useState("");
    const [hasEdited, setHasEdited] = useState(false);

    // Form Data
    const defaultFormData = {
        expenseDescription: "",
        expenseAmount: "",
        expenseDate: `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`,
        expenseCategoryId: props.budget.expenseCategories[0] ? props.budget.expenseCategories[0].expenseCategoryId : "",
        expenseCategoryObj: props.budget.expenseCategories[0] ? props.budget.expenseCategories[0] : {}
    }
    const [formData, setFormData] = useState(defaultFormData);
    const formRef = useRef(null);


    const validateFormData = () => {
        setError("");

        if (hasEdited){
            if (formData.expenseAmount === "" || isNaN(formData.expenseAmount) || parseFloat(formData.expenseAmount) < 0){
                setError("Please enter a valid expense amount!");
            }

            if (!formData.expenseDate){
                setError("Please enter a valid expense date!");
            }

            else if (formData.expenseDate){
                let date = new Date();

                let split = formData.expenseDate.split("-");
                let parsed = new Date();
                parsed.setFullYear(split[0]);
                parsed.setMonth(parseInt(split[1]) - 1);
                parsed.setDate(split[2]);
                parsed.setHours(23);
                parsed.setMinutes(59);
                parsed.setSeconds(59);

                let startOfMonth = new Date();
                startOfMonth.setDate(1);
                startOfMonth.setHours(0);
                startOfMonth.setMinutes(0);
                startOfMonth.setSeconds(0);


                let endOfMonth = new Date();
                endOfMonth.setMonth(date.getMonth() + 1);
                endOfMonth.setDate(0);
                endOfMonth.setHours(23)
                endOfMonth.setMinutes(59);
                endOfMonth.setSeconds(59);

                if (parsed < startOfMonth || parsed > endOfMonth){
                    setError("Expense date must be within this month!");
                }
            }
        }
    }

    useEffect(() => {
        validateFormData();
    }, [formData]);

    // CREATE CATEGORY Hook
    // POST - Creates an expense category
    const {mutate: createExpense, status: createExpenseStatus, isLoading: createExpenseLoading,
        isSuccess: createExpenseIsSuccess, isError: createExpenseIsError} = useCreateExpense();
    const onCreateExpense = () => {
        if (error) return;

        let splitDate = formData.expenseDate.split("-");
        let formattedDate = `${splitDate[1]}-${splitDate[2]}-${splitDate[0]}`;

        createExpense({
            parentBudgetId: props.budget.budgetId,
            expenseDescription: formData.expenseDescription,
            expenseDate: formattedDate,
            expenseAmount: parseFloat(formData.expenseAmount),
            expenseCategoryId: formData.expenseCategoryId
        });
    }
    useEffect(() => {
        if (!createExpenseLoading){
            onClose();
            props.refetchBudget();
        }
    }, [createExpenseIsSuccess]);


    // ON MODAL CLOSE
    const onClose = () => {
        setError("");
        document.getElementById('add_expense_modal').close()
        resetForm();
    }

    // ON BUDGET DATA CHANGE
    useEffect(() => {
        resetForm();
    }, [props.budget]);


    // RESET AND REBUILD THE FORM DATA
    const resetForm = () => {
        setError("")
        setHasEdited(false);
        setFormData(defaultFormData);
    }


    // ON CATEGORY CHANGE
    const changeCategory = (expenseCategoryId) => {
        let category = {};
        for (const i in props.budget.expenseCategories){
            let currentCategory = props.budget.expenseCategories[i];
            if (currentCategory && currentCategory.expenseCategoryId === expenseCategoryId){
                category = currentCategory;
            }
        }
        setHasEdited(true);
        setFormData({...formData, expenseCategoryId, expenseCategoryObj: category});
    }

    // ON EXPENSE AMOUNT CHANGE
    const changeAmount = (newAmount) => {
        setHasEdited(true);
        setFormData({...formData, expenseAmount: newAmount});
    }

    // ON EXPENSE DATE CHANGE
    const changeDate = (newDate) => {
        setHasEdited(true);
        setFormData({...formData, expenseDate: newDate});
    }

    // ON EXPENSE DESCRIPTION CHANGE
    const changeDescription = (newDescription) => {
        setHasEdited(true);
        setFormData({...formData, expenseDescription: newDescription});
    }

    const ExpenseDate = () => {
        let date = new Date();
        date.setTime(date.getTime() - (date.getTimezoneOffset() * 60000));
        let todayStr = date.toISOString().substring(0, 10);

        let startOfMonth = new Date();
        startOfMonth.setDate(1);
        startOfMonth = startOfMonth.toISOString().substring(0, 10);

        let endOfMonth = new Date();
        endOfMonth.setMonth(date.getMonth() + 1);
        endOfMonth.setDate(0);
        endOfMonth = endOfMonth.toISOString().substring(0, 10);

        return <>
            <input type={"date"} id="expense-date" style={{colorScheme: "dark"}}
                   onChange={(e) => {changeDate(e.target.value)}}
                   value={formData.expenseDate} required={true}
                   className="border text-sm rounded-lg on
                                    block w-full md:w-1/2 lg:w-1/4 p-2.5  bg-gray-700
                                    border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500
                                    focus:border-blue-500"
                    max={endOfMonth}
                    min={startOfMonth}/>
            <button type={"button"} className={"bg-gray-700 px-2 py-1 rounded-md text-white self-center " +
                "transition-colors hover:bg-gray-600 ml-3"}
                    onClick={() => {changeDate(todayStr)}}>
                Today
            </button>
        </>
    }

    return (
        <dialog id="add_expense_modal"
                className={`modal modal-bottom sm:modal-middle bg-gray-800 text-white rounded-md w-auto md-1/3 
                    lg:1/2 xl:w-1/2`}>
            <div className={`modal-box px-10 py-8`}>
                { error && <FormError message={error}/>}
                <div className={"flex w-full"}>
                    <h3 className="font-bold w-36 text-lg modal-title mb-2">Add Expense</h3>
                </div>
                <form onSubmit={onCreateExpense} ref={formRef}
                      className={`${(createExpenseLoading) && "bg-opacity-40 opacity-40"}`}>

                    {/* Expense Amount */}
                    <div className={"form-input-group mt-5"}>
                        <label htmlFor="input-group-1"
                               className="block mb-2 text-sm font-medium text-white">Expense Amount *</label>
                        <div className="relative mb-3">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 opacity-50 mr-1">
                                    <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <input type="number" id="budget-value" onChange={(e) => {changeAmount(e.target.value)}}
                                   value={formData.expenseAmount}
                                   className="border text-sm rounded-lg block w-30 ps-10 p-2.5  bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                   placeholder="Amount ($)" required={true}/>
                        </div>
                    </div>

                    {/* Expense Date */}
                    <div className={"form-input-group"}>
                        <label htmlFor="expense-date"
                               className="block mb-2 text-sm font-medium text-white">Expense Date *</label>
                        <div className="relative flex items-center mb-4">
                            <ExpenseDate/>
                        </div>
                        
                    </div>

                    {/* Expense Categories */}
                    <div className={"form-input-group mt-1"}>
                        <label htmlFor="task-color"
                                className="block mb-2 text-sm font-medium text-white">Category *</label>
                        <Dropdown label={
                            <div className={"flex p-1"}>
                                <div className={"w-4 h-4 rounded-sm mr-2"}
                                        style={{backgroundColor: props.colorsObj[formData.expenseCategoryObj.expenseCategoryColorId] && props.colorsObj[formData.expenseCategoryObj.expenseCategoryColorId].colorHexcode}}/>
                                <p>{formData.expenseCategoryObj.expenseCategoryName}</p>
                            </div>} class={"bg-gray-700 rounded-md"}>
                            {
                                props.budget.expenseCategories.map((category) => {
                                    return (
                                        <div key={category.expenseCategoryColorId}>
                                            <Dropdown.Item onClick={() => {changeCategory(category.expenseCategoryId)}}
                                                class={"bg-gray-700 px-3 py-2 text-sm w-full hover:bg-gray-600 text-white"}>
                                                <div className={"flex p-1 text-white"}>
                                                    <div className={"w-4 h-4 rounded-sm mr-2"}
                                                            style={{backgroundColor: props.colorsObj[category.expenseCategoryColorId].colorHexcode}}/>
                                                    <p>{category.expenseCategoryName}</p>
                                                </div>
                                            </Dropdown.Item>
                                        </div>
                                    );
                                })
                            }
                        </Dropdown>
                    </div>

                    {/* Expense Description */}
                    <div className={"form-input-group mt-3"}>
                        <label htmlFor="expense-description"
                               className="block mb-2 text-sm font-medium text-white">Expense Description</label>
                        <div className="relative mb-4">
                            <input type={"text"} id="expense-description" style={{colorScheme: "dark"}}
                                   onChange={(e) => {changeDescription(e.target.value)}}
                                   value={formData.expenseDescription} required={true}
                                   className="border text-sm rounded-lg on
                           block w-full p-2.5  bg-gray-700
                           border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500
                           focus:border-blue-500" placeholder={"Describe the expense"}/>
                        </div>

                    </div>

                </form>
                <div className="modal-action mt-6">
                    <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button onClick={onClose}
                                className="focus:outline-none text-white focus:ring-4
                                 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 bg-gray-600
                                hover:bg-gray-500 focus:ring-red-900">Cancel
                        </button>
                        <button type="button" onClick={onCreateExpense} disabled={error}
                                className={`px-5 py-2.5 text-sm font-medium text-white
                                focus:ring-4 focus:outline-none rounded-lg text-center bg-blue-600
                                hover:bg-blue-700 focus:ring-blue-800 ${error && "opacity-60"}`}>Save
                        </button>
                    </form>
                </div>
            </div>
        </dialog>
    );
}