import React, {useEffect, useState} from "react";

export default function ClockDisplay(){
    const [clockDate, setClockDate] = useState(new Date());

    useEffect(() => {
        let timer = setInterval(()=>setClockDate(new Date()), 1000);

        return function cleanup(){
            clearInterval(timer);
        }
    })
/**
 * Allows for effective time tracking
 */
    return (
        <div className={"clock"}>
            <h1 className={"font-bold text-4xl"}>
                {clockDate.toLocaleTimeString("en-us", {hour: "numeric", minute: "numeric"})}</h1>
            <h2 className={"text-2xl"}>
                {clockDate.toLocaleDateString("en-us", {weekday: "long", month: "long",
                    day: "numeric"})}</h2>
        </div>
    )
}