import React, {useState, useContext, createContext, useEffect} from "react";
const authContext = createContext(null);

let API_URL = process.env.REACT_APP_API_PROD_BASE_URL


export function ProvideAuth({children}){
    const auth = useProvideAuth();

    return <authContext.Provider value={auth}>
        {children}
    </authContext.Provider>
}

export const useAuth = () => {
    return useContext(authContext);
}

export function useProvideAuth(){
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    function validateUser() {
        setIsLoading(true);
        return fetch(`${API_URL}users/validateUser`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include"
        }).then((res) => res.json().then((data) => {
            if (res.ok){
                setUser(data.user);
                setIsLoading(false);
                return true;
            }
            else {
                setUser(null);
                setIsLoading(false);
                return false;
            }
        }));
    }

    useEffect(() => {
        validateUser().then();
    }, [])

    function login(email, password, rememberMe){
        setIsLoading(true);
        return fetch(`${API_URL}users/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({email, password, rememberMe})
        }).then((res) => {
            setIsLoading(false);
            res.json().then((data) => {
                if (res.ok){
                    setUser(data.user);
                    return true;
                } else {
                    setError(data.error);
                    return false;
                }
            });
        });
    }

    function logout(){
        setIsLoading(true);
        return fetch(`${API_URL}users/logout`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include"
        }).then((res) => res.json().then((data) => {
            setUser(false);
            setIsLoading(false);
            return data;
        }));
    }

    function signup(email, password){
        setIsLoading(true);
        return fetch(`${API_URL}users/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        }).then((res) => res.json().then((data) => {
            if (res.ok){
                login(email, password, false).then();
                return {error: ""}
            }
            else {
                return {
                    error: data.error
                }
            }
        }));
    }

    return {
        user,
        login,
        logout,
        signup,
        error,
        isLoading,
        validateUser
    };
}